.lcs_slide_out-r {
  top: auto !important;
  bottom: 0 !important;
}

.lcs_slide_out-r {
  .lcs_header a {
    top: -1px !important;
    left: -78px !important;
    width: 78px;
    text-decoration: none !important;
    color: $black !important;
    border: 1px solid $gray-medium !important;
    border-radius: 0 !important;
    background-color: $spring-green !important;
    box-shadow: none !important;
    line-height: 1.2;
    &:hover,
    &:focus-visible {
      color: $white !important;
      background-color: $forest-green !important;
    }
    &::before {
      @extend %icon-comments;

      display: block;
      font-size: 2.5rem;
    }
  }
}

.lcs_slide_out-r .lcs_header {
  transform: rotate(0deg) !important;
}

div[id^="lcs_slide_out"].lcs_slide_out.lcs_slide_out-r.canvas-slid {
  right: -800px !important;
}

@media (max-width: em(767px)) {
  .lcs_slide_out-r > .lcs_header > a {
    left: -50px !important;
    width: 50px !important;
    font-size: 0;
    &::before {
      font-size: 24px;
    }
  }
}
