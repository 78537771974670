// @file
// Style for button elements.

.btn-primary {
  color: $black;
  border-color: none;
  background-color: $spring-green;

  &:hover {
    color: $white;
    background-color: $forest-green;
  }
}
