.block-dcl-emergency-banner,
.block-dcl-dart-emergency-banner {
  margin-top: 0;
  font-family: $font-family-base;
  font-size: $base-font-size;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif;
  }
}

#emergency-banner,
#dartmouth-emergency-banner {
  margin-bottom: 0;
  padding: $base-font-size;
  border-left: $base-font-size solid $bright-red;
  background-color: lighten($bright-red, 47%);
  &.info {
    border-left: $base-font-size solid $gold-accent;
    background-color: lighten($gold-accent, 45%);
  }
  #img-blk {
    display: none;
  }
  #msg-blk,
  .dart-msg-txt {
    display: grid;
    grid-template-columns: fit-content(100%) 1fr;
    width: calc(90% - 30px);
    max-width: 1410px;
    margin: 9px auto;
    column-gap: 1rem;
    &::before {
      @extend %icon-warning;

      align-self: center;
      color: $bright-red;
      font-size: $base-font-size * 2.5;
      font-weight: bold;
      line-height: normal;
    }
    .dcl-ebanner-wrapper {
      grid-column: 2;
      align-self: center;
    }
    h2 {
      grid-column: 2;
      align-self: center;
      margin: 0;
    }
    a {
      @include link;
    }
    p {
      grid-column: 2;
      align-self: center;
      max-width: 100%;
      padding-left: 0;
    }
  }

  &.emergency {
    #msg-blk {
      &::before {
        @extend %icon-warning;

        color: $bright-red;
      }
    }
  }
  &.info {
    #msg-blk {
      &::before {
        @extend %icon-info;

        color: $gold-accent;
      }
    }
  }
}

@media (max-width: em(1170px)) {
  #emergency-banner,
  #dartmouth-emergency-banner {
    #msg-blk,
    .dart-msg-txt {
      width: 930px;
    }
  }
}

@media (max-width: em(991px)) {
  #emergency-banner,
  #dartmouth-emergency-banner {
    #msg-blk,
    .dart-msg-txt {
      width: calc(100% - 30px);
      margin-right: 15px;
      margin-left: 15px;
    }
  }
}

@media (max-width: em(767px)) {
  #emergency-banner,
  #dartmouth-emergency-banner {
    font-size: 1em;
  }
}
