// @file
// Custom style for Bootstrap carousel.

.carousel.slide {
  .carousel-indicators {
    position: relative;
    top: 20px;
    left: 30%;

    li {
      margin: 0 8px;
      border: 1px solid $gray;
      background-color: $gray;

      &.active {
        margin: 0 15px;
        border: 1px solid $gold-accent;
        background-color: $gold-accent;
      }
    }
  }

  a.carousel-control {
    position: relative;
    top: -15px;
    border-bottom: none;
    text-shadow: none;

    &.left {
      left: 75%;
      background: none;
    }
    &.right {
      left: 80%;
      background: none;
    }

    &:hover,
    &:focus-visible {
      color: $gold-accent;
    }

    .icon-chevron-left,
    .icon-chevron-right {
      font-size: inherit;
    }
  }

  .carousel-inner .item .carousel-caption {
    position: relative;
    right: 0;
    left: 0;
    padding-bottom: 0;
    text-align: inherit;
    color: inherit;
    text-shadow: none;
    font-family: $font-family-serif;
  }
}
