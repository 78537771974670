.breadcrumbs-and-hero {
  position: relative;
  padding-top: 0;
  color: $white;
  background-color: $dart-green;
  h1 {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: $font-family-base;
    font-size: 85px;
    font-weight: normal;
    line-height: 87%;
    .headings-font-family-h1-loaded & {
      font-family: $headings-font-family-h1;
    }
  }
  .white-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #fff;
  }
  .region-breadcrumbs {
    width: calc(90% - 40px);
    max-width: 1410px;
    margin: 0 auto;
    .breadcrumb {
      margin-top: 0;
      li.active {
        color: $white;
      }
      > li + li::before {
        color: $white;
      }
      a:hover,
      a:focus-visible {
        color: $white;
        border-bottom-color: $spring-green;
      }
    }
  }
  a {
    color: $white;
    border-bottom: 1.5px solid;
    &:hover {
      color: $white;
    }
  }
  #consolidated-banner {
    display: grid;
    grid-template-rows: fit-content(100%) auto 50px;
    grid-template-columns: 3fr 4fr;
    width: calc(90% - 40px);
    max-width: 1410px;
    margin: 0 auto;
    #banner-carousel {
      height: 100%;
      ul li {
        position: static;
      }
    }
    .banner-background {
      position: relative;
    }
    .banner-subheading {
      display: flex;
      padding: 0 0 0 $base-font-size;
      border-left: 2px solid;
      p {
        margin: 5px 0;
        line-height: normal;
      }
    }
    .banner-text-container {
      display: block;
      width: 100%;
      max-width: unset;
      margin-bottom: 12px;
      padding: 0;
      .banner-callout {
        margin-top: 30px;
        padding: 0;
        background-color: transparent;
        .banner-title-wrapper h1 {
          margin-bottom: $base-font-size;
          text-transform: uppercase;
        }
        a {
          color: $white;
          &:hover {
            text-decoration: none;
            color: $white;
            border-bottom-color: $spring-green;
          }
        }
      }
    }
    .left-hero {
      grid-row: 1;
      grid-column: 1;
      align-self: center;
      padding: 0;
      .region-dcl-notices {
        margin: 18px 0;
        .field--name-body {
          margin: 0 15px 0 0;
        }
        p {
          display: inline-block;
          padding: 9px;
          color: $black;
          border-radius: 4px;
          background-color: $spring-green;
          font-size: $base-font-size * 0.775;
        }
        a {
          color: $black;
          border: none;
          &::before {
            @extend %icon-warning;
            @extend %icon-style;

            display: inline-block;
            margin-right: 4px;
            padding-bottom: 2px;
            font-size: $base-font-size;
          }
          &:hover {
            text-decoration: solid underline $black;
            text-decoration-thickness: 2px;
            border: none;
          }
        }
      }
    }
    .hours-hero {
      grid-row: 2;
      grid-column: 1;
      align-self: end;
      padding: 0;
    }
    .right-hero {
      position: relative;
      z-index: 2;
      grid-row: 1 / span 3;
      grid-column: 2;
      min-height: 350px;
      padding: 36px 36px 27px 20px;
      background-image: url("#{$images_path}/frame_square_spring_green.svg");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 140px;
      .right-hero-interior {
        position: absolute;
        top: 0;
        right: 0;
        width: 90%;
        height: 90%;
        background-image: url("#{$images_path}/interior-hero-squiggle.png");
        background-repeat: no-repeat;
        background-position: right top;
        background-size: contain;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .banner-image-about {
        right: 45px;
        bottom: 36px;
        margin-left: 27px;
        font-size: $base-font-size * 0.7;
        a {
          padding: 9px 18px;
          text-align: right;
          text-decoration: none;
          border-bottom: none;
          background-color: $forest-green;
          font-size: inherit;
          font-style: normal;
          &:hover {
            text-decoration: solid underline $spring-green;
            text-decoration-thickness: 2px;
          }
          &::after {
            @extend %icon-info-i;
            @extend %icon-style;

            display: inline-block;
            width: 18px;
            height: 18px;
            margin-left: 9px;
            text-align: center;
            vertical-align: top;
            color: #000;
            border-radius: 50%;
            background-color: #a5d75f;
          }
        }
      }
    }
    .lower-hero {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row: 3;
      grid-column: 1 / span 2;
      align-content: end;
      background-color: $white;
    }
    &.top-banner {
      .right-hero img {
        object-position: top;
      }
    }
    &.bottom-banner {
      .right-hero img {
        object-position: bottom;
      }
    }
  }
}
.part-of-dept-or-project {
  font-family: $font-family-base;
  font-size: $base-font-size * 1.25;
  font-weight: bold;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif;
  }
}
.hero-menu-wrapper {
  display: grid;
  grid-template-columns: fit-content(100%);
  max-width: 1450px;
  margin-bottom: $component-spacing-bottom;
  padding-bottom: 18px;
  padding-left: 20px;
  &.dept-variation {
    width: calc(100% - 36px);
    margin-left: 21px;
    padding-top: $base-font-size * 0.33333;
    background-color: $light-dart-green;
  }
  .region-dcl-notices {
    margin: 18px 0;
    .field--name-body {
      margin: 0 15px 0 0;
    }
    p {
      display: inline-block;
      padding: 9px;
      color: $black;
      border-radius: 4px;
      background-color: $spring-green;
      font-size: $base-font-size * 0.775;
    }
    a {
      color: $black;
      border: none;
      &::before {
        @extend %icon-warning;
        @extend %icon-style;

        display: inline-block;
        margin-right: 4px;
        padding-bottom: 2px;
        font-size: $base-font-size;
      }
      &:hover {
        text-decoration: solid underline $black;
        text-decoration-thickness: 2px;
        border: none;
      }
    }
  }
}

@media (max-width: em(1170px)) {
  .breadcrumbs-and-hero {
    .region-breadcrumbs,
    #consolidated-banner {
      width: auto;
      max-width: 930px;
    }
  }
}

@media (max-width: em(991px)) {
  .breadcrumbs-and-hero {
    background-size: 45%;
    .region-breadcrumbs {
      margin-left: 20px;
    }
    #consolidated-banner {
      grid-template-rows: fit-content(100%) 1fr 50px;
      width: 100%;
      max-width: 100%;
      .left-hero,
      .hours-hero {
        margin-left: 0;
        padding-left: $base-font-size;
      }
      .right-hero {
        min-height: 275px;
        padding-right: $base-font-size;
        background-size: 29%;
      }
    }
  }
}

@media (min-width: em(768px)) {
  .hero-menu-wrapper {
    width: 750px;
  }
}
@media (min-width: em(992px)) {
  .hero-menu-wrapper {
    width: 970px;
  }
}
@media (min-width: em(1170px)) {
  .hero-menu-wrapper {
    width: 90%;
  }
}

@media (min-width: em(768px)) and (max-width: em(992px)) {
  .hero-menu-wrapper {
    width: 100% !important;
    &.dept-variation {
      width: calc(100% - 36px) !important;
    }
  }
}

@media (max-width: em(767px)) {
  .breadcrumbs-and-hero {
    background-image: none;
    background-size: 66%;
    .white-bottom {
      display: none;
    }
    #consolidated-banner {
      grid-template-rows:
        fit-content(100%)
        1fr
        fit-content(100%)
        fit-content(100%);
      grid-template-columns: 100%;
      .banner-text-container {
        margin-bottom: 0;
      }
      .left-hero {
        grid-row: 1;
        grid-column: 1;
        .banner-callout {
          margin-top: 9px;
          .banner-title-wrapper h1 {
            margin-bottom: 9px;
            padding: 0;
            font-size: $base-font-size * 2.75;
          }
        }
        section.harmful-content-notice {
          margin: 0 15px 0 0;
        }
      }
      .hours-hero {
        grid-row: 3;
      }
      .right-hero {
        grid-row: 2;
        grid-column: 1;
        padding: 18px 64px 27px 18px;
      }
      .lower-hero {
        grid-row: 4;
        grid-column: 1;
        background-color: $white;
      }
    }
  }
}

// Project page override to remove the squiggle and grid
.dcl-dcl-project,
.dcl-dartmouth-slavery-project {
  .breadcrumbs-and-hero {
    #consolidated-banner .right-hero {
      padding-top: $base-font-size;
      padding-right: 0;
      background: none;
      .banner-text-container .banner-callout {
        margin-top: $base-font-size * 0.7;
      }
      .right-hero-interior {
        background: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .dcl-dcl-project .breadcrumbs-and-hero {
    #consolidated-banner .right-hero {
      padding-right: $base-font-size;
    }
  }
}

// basic and hub pages
.page-node-type-page,
.page-node-type-hub-page {
  .main-container.container .hero-menu-wrapper {
    display: none;
  }
}

.basic-hero-wrapper {
  color: $white;
  background-color: $dart-green;
  .hero-menu-wrapper {
    grid-template-columns: 1fr;
  }
  h1 {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: $font-family-base;
    font-size: 85px;
    font-weight: normal;
    line-height: 87%;
    .headings-font-family-h1-loaded & {
      font-family: $headings-font-family-h1;
    }
  }
  .breadcrumb {
    > li + li::before {
      color: $white;
    }
    > li.active {
      color: $white;
    }
    a {
      color: $white;
      border-bottom: 0.5px solid;
      &:hover,
      &:focus-visible {
        border-bottom-color: $spring-green;
      }
    }
  }
  .dcl-news & {
    .hero-menu-wrapper {
      max-width: $max-text-width;
    }
  }
  .basic-hero-background {
    display: block;
    width: 100%;
    height: 80px;
    margin: -40px 0;
    content: " ";
    background-image: url("#{$images_path}/blue_asterisk.png");
    background-repeat: no-repeat;
    background-position: right 15px;
    background-size: contain;
  }
}

@media (max-width: 767px) {
  .basic-hero-wrapper {
    padding: 0 15px;
    h1 {
      margin-top: 0;
      margin-bottom: $base-font-size * 0.5;
      font-size: $base-font-size * 2.75;
      line-height: normal;
    }
  }
  .main-container.container .hero-menu-wrapper {
    position: relative;
    display: block;
    max-width: calc(100% - 15px);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    border: none;
    background-color: transparent;
  }
  .page-node-type-collection .hero-menu-wrapper {
    padding-left: 5px;
  }
  .page-node-type-page,
  .page-node-type-hub-page {
    .main-container.container .hero-menu-wrapper {
      display: block;
      max-width: 100%;
    }
  }
}
