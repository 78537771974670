// @file _image.scss

// teaser view of an image style.
.teaser {
  img {
    position: relative;
    float: left;
    padding: 0.5em;
  }

  li.node-readmore {
    float: right;
  }
}

.main-content {
  img {
    @extend .img-responsive;

    width: 100%;
  }
}

// popular services
// paragraph field contains inline images
.field--name-field-content,
.field--name-body {
  img.img-responsive {
    width: 100%;
    max-width: $max-text-width;
    margin: 10px 0;
  }

  img[src*="\/inline_image\/"] {
    max-width: 450px;
  }

  .align-right {
    padding-left: 1.5em;
  }

  .align-left {
    padding-right: 1.5em;
  }

  .align-center img {
    width: auto;
    max-width: 100%;
    margin: auto;
  }

  .align-left .field--name-field-caption,
  .align-right .field--name-field-caption,
  .align-center .field--name-field-caption {
    @include caption-basic;

    max-width: 450px;
  }
}
