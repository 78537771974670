// @file
// Style for the footer elements.

footer {
  width: 100%;
  margin-top: $base-font-size !important;
  padding: 40px 0 20px;
  color: $white;
  background: $forest-green;
  font-size: $base-font-size * 0.8;
  div.container {
    width: 100%;
    padding: 0 20px;
  }
  > .container > .row:first-of-type {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5rem;
    width: calc(100% + 30px);
    margin-bottom: $component-spacing-bottom * 0.5;
    padding-bottom: $component-spacing-bottom * 0.5;
    border-bottom: 1px solid $white;
  }
  > .container > .row {
    &::after,
    &::before {
      display: none;
    }
  }
  h2 {
    margin: 0;
    padding: 10px 0 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white;
    font-size: $base-font-size * 0.6;
    a:hover,
    a:focus-visible {
      cursor: pointer;
      border-bottom: none;
    }
  }
  a,
  ul.nav li a {
    position: static;
    display: inline;
    margin-bottom: 4px;
    padding: 2px 0;
    color: $white;
    border-bottom: 1.5px solid transparent;
    outline: solid 5px transparent;
    &.is-active {
      background-color: transparent;
    }
    &:hover,
    &:focus-visible {
      text-decoration: none;
      color: $white;
      border-bottom: 1.5px solid $spring-green;
      background: none;
      box-shadow: none;
    }
  }
  ul.menu--footer-library-links > li.first {
    margin-bottom: 0;
  }
  li {
    display: block;
    margin-bottom: $base-font-size * 0.5;
  }
  ul.nav li ul {
    box-shadow: none;
    li {
      margin-bottom: $base-font-size * 0.3;
      a {
        border-bottom: 1.5px solidtransparent;
        font-size: $base-font-size * 0.8;
        line-height: $base-font-size * 0.9;
        &.is-active {
          background-color: transparent;
        }
        &:hover,
        &:focus-visible {
          text-decoration: none;
          color: $white;
          border-bottom: 1.5px solid $spring-green;
          background: none;
          box-shadow: none;
        }
      }
    }
  }
  ul.library-info {
    padding-left: 0;
    list-style: none;
  }
  .region-footer-first,
  .region-footer-second,
  .region-footer-third,
  .region-footer-fourth {
    border-bottom: none;
  }
  .region-footer-fourth {
    min-height: 200px;
  }
  ul.footer-social {
    padding: 0;
    list-style: none;
    li {
      margin-right: $base-font-size * 0.5;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        width: $base-font-size * 2;
        height: $base-font-size * 2;
        color: $black;
        border: none;
        border-radius: 50%;
        background-color: $spring-green;
        &::after {
          margin-left: 0;
          font-size: $base-font-size * 1.333;
          line-height: $base-font-size * 1.775;
        }
        &:hover {
          color: $white;
          border: none;
          background-color: $black;
        }
      }
    }
  }
  .region-footer-copyright {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 2rem;
    align-items: end;
    width: calc(100% - 2rem);
    margin: 0;
    padding: 6px;
    text-align: center;

    &::before {
      display: none;
    }

    > * {
      align-self: center;
      margin: 0;
      .field--name-body {
        margin: 0;
      }
    }

    > :not(.display-footer) {
      @extend %visually-hidden;
    }

    ul.menu--footer-copyright {
      a {
        line-height: 2;
      }
    }

    .block-system-branding-block {
      justify-self: left;
      height: 100%;
      a {
        height: 100%;
        border-bottom: 2px transparent !important;
        background-image: url("#{$images_path}/Dartmouth_Libraries_Lockup_White_RGB.png");
        background-repeat: no-repeat;
        background-size: contain;
        img {
          width: $base-font-size * 8.25;
          max-width: 100%;
          margin-bottom: $base-font-size * -1;
          opacity: 0;
        }
      }
    }

    nav {
      display: inline;
    }

    #block-footerconnectwithus {
      justify-self: right;
    }

    // override the max width for p & ul set for body content
    ul {
      max-width: 100%;
    }
    li {
      display: inline-block;
      max-width: 100%;
      margin: 0;
      a {
        display: inline-block;
      }
    }

    #block-bootstrap-dcl-footercopyright li {
      display: block;
    }

    ul.nav li {
      padding: 0;
      &::before {
        margin: 0 0.4em;
        vertical-align: middle;
        font-size: 0.8rem;
      }
    }
  }

  // Branch library list: make submenu visible by default, overriding bootstrape sub-menu theme
  nav .menu .expanded {
    .caret {
      display: none;
    }
    .dropdown-menu {
      position: relative;
      z-index: 1;
      display: block;
      float: none;
      padding-left: 15px;
      border: none;
      background: transparent;
    }
  }

  #block-footercontactus {
    float: none;
    padding: 0;
    h2 > span {
      margin: 0;
      padding: 0;
    }

    #footer-contactus-collapse {
      display: block;
    }
    h2 > span {
      &:hover,
      &:focus-visible {
        margin-bottom: 0 !important;
        cursor: text;
      }
      > span {
        display: none;
      }
    }
  }

  #block-footeraboutus {
    float: none;
    padding: 0;
    h2 span {
      margin: 0;
      padding: 0;
    }

    #footer-aboutus-collapse {
      display: block;
    }

    h2 > span {
      &:hover,
      &:focus-visible {
        margin-bottom: 0 !important;
        cursor: text;
      }
      > span {
        display: none;
      }
    }
  }
} // End footer

@media (max-width: em(767px)) {
  footer {
    margin-top: 0;
    > .container > .row:first-of-type {
      grid-template-columns: 100%;
    }
    .block-system-branding-block a img {
      width: $base-font-size * 15 !important;
      max-width: 100%;
    }

    .region-footer-copyright {
      grid-template-columns: 100%;
      text-align: left;
      #block-footerconnectwithus {
        justify-self: left;
        .footer-social a {
          text-align: center;
        }
      }
    }
  }
}

@media (min-width: em(768px)) {
  footer {
    // Add a bit additional padding on the right for the Dartmouth Library section
    .region-footer-first {
      max-width: 80%;
      padding-left: 6px;
    }

    .region-footer-fourth {
      border-left: solid 1px $off-white;
    }
  }
}
