// @file _mixins.scss
$images_path: "../../" !default;

@mixin type-scale($scale, $map: $type-scale) {
  $font-size: map-fetch($map, $scale font-size);
  $base-lines: map-fetch($map, $scale base-lines);

  @include adjust-font-size-to($font-size, $base-lines);
}

@mixin type-weight($weight) {
  font-weight: map-get($type-weight, $weight);
}

@mixin first-letter {
  float: left;
  margin: $base-font-size * 0.3 $base-font-size * 0.5 $base-font-size * 0.15 0;
  padding-top: $base-font-size * 0.3;
  font-family: serif;
  font-size: $base-font-size * 4;
  font-weight: 400;
  line-height: $base-font-size * 3;
  .font-family-serif-loaded & {
    font-family: $font-family-serif;
  }
}

@mixin link($important: "") {
  // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
  & {
    color: $link-color unquote($important);
    &:active {
      color: $link-active unquote($important);
    }
    &:visited {
      color: $link-visited unquote($important);
    }
    &:hover {
      color: $link-hover unquote($important);
    }
    &:focus-visible {
      color: $link-focus unquote($important);
    }
  }
}

@mixin border-bottom-link($important: "") {
  @include link($important);

  text-decoration: none unquote($important);
  border-bottom: 1.5px solid $dart-green unquote($important);
  &:hover {
    border-bottom-color: $spring-green;
  }
}

@mixin label-link($color) {
  // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
  & {
    text-transform: uppercase;
    color: $color;
    font-family: $font-family-sans-serif;
    font-size: 1.65em;
    line-height: 1.2em;

    .headings-font-family-others-loaded & {
      font-family: $headings-font-family-others;
    }
    &:active {
      color: $color;
    }
    &:visited {
      color: $color;
    }
    &:hover,
    &:focus-visible {
      text-decoration: underline;
      color: $color;
    }
  }
}

/// No need to be a mixin, used by libhours.scss only
@mixin more-link {
  text-align: right;
  a,
  a:active,
  a:link,
  a:visited {
    @include link;

    display: inline;
    margin: 1rem 0;
    padding: 0.5rem 2.5rem;
    text-decoration: none;
    letter-spacing: 0.5px;
    color: $white !important;
    border: none;
    border-radius: $base-font-size * 2;
    background-color: $dart-green;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: $white;
      border: none;
      background-color: $forest-green;
    }
    &::after {
      @extend %icon-arrow-right;

      margin-left: $base-font-size;
      vertical-align: middle;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

%bare-more-link {
  @include link;

  display: inline;
  float: right;
  margin: 1rem 0;
  padding: 0.5rem 2.5rem;
  text-decoration: none;
  letter-spacing: 0.5px;
  color: $white !important;
  border: none;
  border-radius: $base-font-size * 2;
  background-color: $dart-green;
  font-weight: bold;
  line-height: normal;
  &:hover {
    text-decoration: none;
    color: $white;
    border: none;
    background-color: $forest-green;
  }
  &::after {
    @extend %icon-arrow-right;

    margin-left: $base-font-size;
    vertical-align: middle;
    text-decoration: none;
    font-weight: bold;
  }
}

%pill-link {
  @extend %bare-more-link;

  display: inline-block;
  float: none;
}

%pill-link-plain {
  @extend %bare-more-link;

  display: inline-block;
  float: none;
  color: $black !important;
  background-color: $off-white;
  &:hover {
    color: $white !important;
  }
}

%footer-link {
  text-decoration: none;
  color: $off-white;
  &:hover,
  &:focus-visible {
    text-decoration: none;
    border-bottom: 1.5px solid $gray;

    // outline: 3px solid $yellow;
  }
}

// No need to be a mixin
@mixin body-link {
  // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
  & {
    text-decoration: none;
    color: $link-color;
    border-bottom: 1.5px solid $link-color;

    &:hover,
    &:focus-visible {
      color: $dart-green;
      border-color: $spring-green;
    }
  }
}

/// Transitions
@mixin height-transition {
  -webkit-transition: height 0.5s ease;
  -moz-transition: height 0.5s ease;
  -o-transition: height 0.5s ease;
  transition: height 0.5s ease;
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin material-icon($character) {
  content: "#{$character}";
  font-family: $material-symbols;
  font-variation-settings: "FILL" 1;
}

@mixin fontawesome-icon($character) {
  margin-left: 4px;
  content: "#{$character}";
  font: $font-awesome;
}

%more-link {
  text-align: right;
  a,
  a:active,
  a:link,
  a:visited {
    @include link;

    display: inline;
    margin: 1rem 0;
    padding: 0.5rem 2.5rem;
    text-decoration: none;
    color: $white !important;
    border-radius: $base-font-size;
    background-color: $dart-green;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: $white;
      background-color: $forest-green;
    }
    &::after {
      @extend %icon-arrow-right;

      margin-left: $base-font-size;
      vertical-align: middle;
      text-decoration: none;
    }
  }
}

%-link {
  text-decoration: none;
  color: $off-white;
  &:hover,
  &:focus-visible {
    text-decoration: none;
    border-bottom: 1.5px solid $gray;

    // outline: 3px solid $yellow;
  }
}

%body-link {
  text-decoration: none;
  color: $link-color;
  border-bottom: 1.5px solid $link-color;

  &:hover,
  &:focus-visible {
    color: $dart-green;
    border-bottom: 1.5px solid $spring-green;
  }
}

%no-padding-left {
  padding-left: 0 !important;
}

// grid
@mixin dcl-container {
  @media (min-width: em(1171px)) {
    width: 90%;
    max-width: 1450px;
  }
}

@mixin grid-rows($numRows) {
  @for $i from 1 through $numRows {
    $gridVals: ();

    @for $v from 1 through $i {
      // stylelint-disable-next-line scss/no-duplicate-dollar-variables
      $gridVals: append($gridVals, unquote("1fr"), space);
    }

    // stylelint-disable-next-line order/order
    &.menu-rows-#{$i} {
      -ms-grid-rows: $gridVals;
      grid-template-rows: $gridVals;
    }
  }
}

@mixin ie-grid-placement($numRows, $numColumns) {
  @for $i from 1 through $numRows {
    @for $v from 1 through $numColumns {
      &.ms-grid-#{$i}-#{$v} {
        grid-row: #{$i};
        grid-column: #{$v};
      }
    }
  }
}

// responsive video
%responsive-video {
  margin: 0 auto;

  .field--item {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

// paragraphs

// paragraph header gradient
@mixin paragraph-header-gradient($color_from, $color_to) {
  h2:first-of-type {
    &::before {
      display: table;
      width: 200px;
      height: 4px;
      margin-bottom: 8px;
      content: " ";
      background: linear-gradient(90deg, $color_from 0%, $color_to 100%);
    }
  }
}

// mixins and placeholders for banner paragraph
@mixin banner-image-about {
  .banner-image-about {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 1px;
    max-width: 550px;
    margin-top: 1rem;
    border: 0;
    a {
      display: inline-block;
      width: auto;
      padding: 3px 5px;
      text-decoration: dotted underline $white;
      text-decoration-thickness: 2px;
      letter-spacing: 1px;
      color: $white;
      background-color: $gray-dark;
      font-family: $font-family-serif-base;
      font-size: 1.2rem;
      .font-family-serif-loaded & {
        font-family: $font-family-serif;
      }
    }
  }
}

// image caption, basic style: gray text on white background
@mixin caption-basic {
  display: table-cell;
  max-width: 100%;
  margin: 0 0 10px;
  padding: 10px;
  vertical-align: middle;
  color: $gray-intro;
  background: 0 0;
  font-size: 1em;
  font-style: italic;
  line-height: 1.5em;
}

@mixin select-style {
  // see https://www.filamentgroup.com/lab/select-css.html
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
  height: 4.5rem;
  margin: 0 0 0 1.1em;
  padding: 0.4em 2em 0.3em 0.8em;
  color: lighten($gray-dark, 15%);
  border: 1px solid $gray-medium;
  border-radius: 4px;
  background-color: $white;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, $white 0%, $white 100%);
  background-repeat: no-repeat, repeat;
  background-position:
    right 0.7em top 50%,
    0 0;
  background-size:
    0.65em auto,
    100%;
  appearance: none;

  &::-ms-expand {
    display: none;
  }
  &:hover {
    outline: solid 3px $blue-light !important;
  }
  &:focus-visible {
    outline: solid 3px $yellow !important;
  }
  option {
    font-weight: normal;
  }
}

// cards
%hexagon-background-spring-green {
  background-color: transparent;
  background-image: url("#{$images_path}/hexagon-spring-green.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

%hexagon-background-forest-green {
  background-color: transparent;
  background-image: url("#{$images_path}/hexagon-forest-green.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

%cards-layout {
  > .view-content,
  .field--name-field-tool-or-service,
  .field--name-field-card {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: $base-font-size * 4 $base-font-size;
    > .views-row,
    > .field--item {
      margin-left: $base-font-size * 1.5;
      background: $white;
    }
    .cards-content {
      display: grid;
      grid-template-rows:
        fit-content(100%)
        fit-content(100%)
        1fr
        fit-content(100%);
      height: 100%;
      .cards-title {
        display: inline-block;
        margin-bottom: $base-font-size;
        font-size: $base-font-size * 1.3333;
        font-weight: bold;
        line-height: 1.15;
      }
      .cards-link {
        justify-self: right;
        a {
          color: $white;
          border-bottom: 1.5px solid $white;
          font-size: $base-font-size * 0.7;
          font-weight: bold;
          &:hover {
            border-bottom: 1.5px solid $white;
          }
        }
      }
    }
  }
  &.num-cards-1 {
    > .view-content,
    .field--name-field-tool-or-service,
    .field--name-field-card {
      grid-template-columns: 33%;
    }
    .view-footer {
      width: 33%;
    }
  }
  &.num-cards-2 {
    > .view-content,
    .field--name-field-tool-or-service,
    .field--name-field-card {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  &.num-cards-3,
  &.num-cards-6 {
    > .view-content,
    .field--name-field-tool-or-service,
    .field--name-field-card {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  &.num-cards-4 {
    > .view-content,
    .field--name-field-tool-or-service,
    .field--name-field-card {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  // for probable inclusion later - 2x2 grid for 4 cards
  // &.num-cards-4 {
  //   > .view-content,
  //   .field--name-field-tool-or-service,
  //   .field--name-field-card {
  //     grid-template-columns: repeat(2, minmax(0, 1fr));
  //     > .field--item {
  //       justify-self: right;
  //       width: 100%;
  //       max-width: 450px;
  //       &:nth-of-type(2n) {
  //         justify-self: left;
  //       }
  //     }
  //   }
  // }

  &.num-cards-5 {
    > .view-content,
    .field--name-field-tool-or-service,
    .field--name-field-card {
      grid-template-columns: repeat(6, minmax(0, 1fr));
      > .views-row:nth-of-type(1),
      > .views-row:nth-of-type(2),
      > .views-row:nth-of-type(3),
      > .field--item:nth-of-type(1),
      > .field--item:nth-of-type(2),
      > .field--item:nth-of-type(3) {
        grid-column: span 2;
      }
      > .views-row:nth-of-type(5),
      > .field--item:nth-of-type(5) {
        grid-column: span 2;
      }
      > .views-row:nth-of-type(4),
      > .field--item:nth-of-type(4) {
        grid-column: 2 / span 2;
      }
    }
  }
  &.num-cards-7 {
    > .view-content,
    .field--name-field-tool-or-service,
    .field--name-field-card {
      grid-template-columns: repeat(6, minmax(0, 1fr));
      > .views-row:nth-of-type(1),
      > .views-row:nth-of-type(2),
      > .views-row:nth-of-type(3),
      > .views-row:nth-of-type(4),
      > .views-row:nth-of-type(5),
      > .views-row:nth-of-type(6),
      > .field--item:nth-of-type(1),
      > .field--item:nth-of-type(2),
      > .field--item:nth-of-type(3),
      > .field--item:nth-of-type(4),
      > .field--item:nth-of-type(5),
      > .field--item:nth-of-type(6) {
        grid-column: span 2;
      }
      > .views-row:nth-of-type(7),
      > .field--item:nth-of-type(7) {
        grid-column: 4 / span 2;
      }
    }
  }

  @media (max-width: 991px) {
    &.num-cards-1,
    &.num-cards-2,
    &.num-cards-4,
    &.num-cards-6 {
      > .view-content > .views-row,
      .field--name-field-tool-or-service,
      .field--name-field-card > .field--item {
        grid-column: span 1;
      }
    }
    &.num-cards-1 {
      > .view-content,
      .field--name-field-tool-or-service,
      .field--name-field-card {
        grid-template-columns: 50%;
      }
      .view-footer {
        width: 50%;
      }
    }
    &.num-cards-2,
    &.num-cards-4,
    &.num-cards-6 {
      > .view-content,
      .field--name-field-tool-or-service,
      .field--name-field-card {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
    &.num-cards-3 {
      > .view-content,
      .field--name-field-tool-or-service,
      .field--name-field-card {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        > .views-row:nth-of-type(3),
        > .field--item:nth-of-type(3) {
          grid-column: span 2;
        }
      }
    }
    &.num-cards-5 {
      > .view-content,
      .field--name-field-tool-or-service,
      .field--name-field-card {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        > .views-row:nth-of-type(1),
        > .views-row:nth-of-type(2),
        > .views-row:nth-of-type(3),
        > .field--item:nth-of-type(1),
        > .field--item:nth-of-type(2),
        > .field--item:nth-of-type(3),
        > .views-row:nth-of-type(4),
        > .field--item:nth-of-type(4),
        > .views-row:nth-of-type(5),
        > .field--item:nth-of-type(5) {
          grid-column: span 1;
        }
      }
    }
    &.num-cards-7 {
      > .view-content,
      .field--name-field-tool-or-service,
      .field--name-field-card {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        > .views-row:nth-of-type(7),
        > .field--item:nth-of-type(7) {
          grid-column: span 2;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.num-cards-1,
    &.num-cards-2,
    &.num-cards-3,
    &.num-cards-4,
    &.num-cards-5,
    &.num-cards-6 {
      > .view-content,
      .field--name-field-tool-or-service,
      .field--name-field-card {
        grid-template-columns: 100%;
        grid-auto-flow: row;
        > .views-row:nth-of-type(n),
        > .field--item:nth-of-type(n) {
          grid-column: span 1;
        }
      }
      .view-footer {
        width: 100%;
      }
    }
  }
}

%cards-type-or-time {
  display: block;
  margin-bottom: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 115%;
}

%cards-base {
  position: relative;
  height: 100%;
  padding: 2rem 2rem 2rem 4rem;
  color: $white;
  background-color: $forest-green;
  background-image: url("#{$images_path}/frame_spring_green.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  .hexagon-icon {
    @extend %hexagon-background-spring-green;

    position: absolute;
    top: $base-font-size * 3;
    left: 0;
    width: $base-font-size * 3.5;
    height: $base-font-size * 3.5;
    translate: -50% -25%;
    text-align: center;
    &::before {
      position: absolute;
      top: 50%;
      translate: -50% -50%;
      color: $black;
      font-size: $base-font-size * 1.5;
    }
  }
}

%card-white {
  .cards-wrapper {
    color: $black;
    border: 1px solid $spring-green;
    background-color: $white;
    .cards-link,
    .cards-description {
      a {
        color: $dart-green;
        border-bottom: 1.5px solid $dart-green;
        &:hover {
          color: $dart-green;
          border-bottom: 1.5px solid $spring-green;
        }
      }
    }
  }
}

%card-colors {
  // 3, 5, 7, 9
  &:nth-of-type(2n + 3) {
    .cards-wrapper {
      background-color: $spring-green;
      background-image: url("#{$images_path}/frame_forest_green.svg");
      .hexagon-icon {
        @extend %hexagon-background-forest-green;

        &::before {
          color: $white;
        }
      }
      .cards-content {
        color: $forest-green;
      }
      .cards-link,
      .cards-description {
        a {
          color: $forest-green;
          border-bottom: 1.5px solid $forest-green;
          &:hover {
            border-bottom: 1.5px solid $forest-green;
          }
          &:focus-visible {
            outline-color: $forest-green !important;
          }
        }
      }
      .field--name-field-call-to-action a {
        &:hover,
        &:focus {
          color: $white !important;
          background-color: $forest-green !important;
        }
      }
    }
  }

  // 2, 4, 6, 8
  &:nth-of-type(2n + 2) {
    .cards-wrapper {
      background-color: $dart-green;
      .hexagon-icon {
        @extend %hexagon-background-spring-green;
      }
      .field--name-field-call-to-action a {
        color: $black !important;
        background-color: $spring-green;
        &:hover,
        &:focus {
          color: $white !important;
          background-color: $forest-green;
        }
      }
    }
  }

  // 6, 12
  &:nth-of-type(6n) {
    .cards-wrapper {
      background-color: $forest-green;
      .hexagon-icon {
        @extend %hexagon-background-spring-green;
      }
      .field--name-field-call-to-action a {
        &:hover,
        &:focus {
          color: $white;
          background-color: $spring-green;
        }
      }
    }
  }
}

// typography
%h2 {
  margin: $base-font-size 0;
  letter-spacing: -0.36px;
  font-family: $font-family-base;
  font-size: 36px;
  font-weight: bold;
  line-height: 100%;

  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif;
  }
}

%visually-hidden {
  // visually aria-hidden
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  border: 0;
}

// visually aria-hidden
.visually-hidden:not(:focus-visible, :active) {
  @extend %visually-hidden;
}

%dcl-a {
  color: $dart-green;
  &:hover {
    color: $blue;
  }
  &:active {
    color: $blue;
  }
}

%icon-style {
  vertical-align: middle;
  font-style: normal;
}
