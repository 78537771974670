// @file
// Style for links for the main content areas.

body .region-home-feature-primary-third,
.region-home-feature-primary-second,
.region-sibdebar-feature {
  a {
    @include link;
  }
}

.region-home-feature-secondary-second .home-feature-news-blogs {
  a {
    @extend %body-link;
  }
}

.region-content,
.dcl-hours-service-point-set {
  h2,
  h3,
  h4,
  .h2,
  .h3,
  .h4 {
    a {
      color: $dart-green;

      &:hover,
      &:focus-visible {
        text-decoration: none;
        color: $dart-green;
        border-bottom: 1.5px solid $spring-green;

        span {
          background: $green-accent;
        }
      }
    }
  }

  a {
    color: $dart-green;
    border-bottom: 1.5px solid $dart-green;

    &:hover,
    &:focus-visible {
      text-decoration: none;
      color: $dart-green;
      border-bottom: 1.5px solid $spring-green;
    }
  }
}

.mega-menu-external-link {
  &::after {
    @extend %icon-link;

    display: inline-block;
    margin-left: 4px;
    vertical-align: sub;
  }
}

// for promo use eventually
.dcl-see-all {
  color: $dart-green;
  font-size: em($base-font-size);
  &::after {
    @extend %icon-chevron-right;

    margin-left: 5px;
    color: $gray;
  }
}

// with fixed header, add some offset to anchor links
:target::before {
  display: block;
  height: 80px;
  margin: -80px 0 0;
  content: "";
}

a.button--call-to-action {
  @extend %bare-more-link;

  margin-right: $base-font-size;
  color: $white;
  &:first-of-type {
    margin-right: 0;
  }
}
.main-container.container fieldset legend a.filter-label {
  border: none;
  &:hover {
    border: none;
  }
}

// ckeditor read-more widget style
.ckeditor-readmore-wrapper {
  max-width: $max-text-width;
  text-align: right;
  .ckeditor-readmore {
    text-align: left;
  }
  .ckeditor-readmore-toggler {
    @extend %pill-link;
    &.more {
      &::after {
        @extend %icon-expand-more;
        @extend %icon-style;

        color: $white;
      }
    }
    &.less {
      &::after {
        @extend %icon-expand-less;
        @extend %icon-style;

        color: $white;
      }
    }
  }
}
