@use "sass:math";

// Omni Update specific styles

// declare some variable overrides for font paths
$base-font-path: "//library.dartmouth.edu/sitelib/";
$manuka-font-path: "#{$base-font-path}" !default;
$national-font-path: "#{$base-font-path}" !default;
$ruzicka-font-path: "#{$base-font-path}" !default;
$glyphicons-font-path: "#{$base-font-path}bootstrap-3.3.4/fonts/";
$icon-font-path: $glyphicons-font-path;

:root {
  --fa-font-brands: normal 400 1em/1 "FontAwesome";
}

// import drupal core css that we will need
@import "drupal_core_for_outside_services";

// custom theme style
@import "drupal_core_base_styles";

// mixins based on bootstrap to prevent bloat
@import "dart_bootstrap_based_mixins";

@mixin ul-style() {
  list-style-type: none;
  li::before {
    display: inline-block;
    float: left;
    width: 1em;
    margin-top: -0.1em;
    margin-left: -1em;
    content: "\25E6";
    color: #328060;
    line-height: 1.9em;
  }
}

// fontawesome fixes
#dept-info ul li.social-links a[href^="https://www.facebook.com"]::before,
.icon-facebook::after {
  content: "\f09a";
}

// emergency banner
#emergency-banner {
  margin-bottom: 0;
}
.block-dcl-emergency-banner {
  font-family: $font-family-base;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif !important;
  }
}

h2,
h3,
h4,
h5,
.h2,
.h3,
.h4,
.h5 {
  line-height: normal;
}

// misc overrides
td,
th {
  padding: 4px;
}

.s-lib-box-container .s-lg-box-footer .s-lg-box-updated {
  color: $black;
}

// make sure the outline style fully renders
.region-primary-menu
  nav.column-menu
  .dropdown
  > .dropdown-menu
  > li.additional-links
  > ul
  li
  a {
  margin-right: 3px;
}

.input-group {
  input {
    height: auto;
    padding: 12px;
  }
  button[type="submit"] {
    border-radius: 0;
  }
}

// override the color for the accounts menu caret
.region-secondary-menu {
  a:hover {
    outline: solid 3px #ffa00f !important;
  }

  li.additional-links {
    a {
      display: inline;
      margin-right: 3px;
    }
  }
  .nav .dropdown-toggle:focus .white-caret,
  .nav .dropdown-toggle:hover .caret {
    border-top-color: $white;
    border-bottom-color: $white;
  }
}

#s-lib-public-nav .nav li.dropdown.open > .dropdown-toggle,
.navbar .nav li.dropdown.active > .dropdown-toggle,
.navbar .nav li.dropdown.open.active > .dropdown-toggle {
  color: $black;
}

.basic-hero-wrapper {
  height: 3px;
  .hero-menu-wrapper {
    display: none;
  }
  .basic-hero-background {
    background: none;
  }
}
.dcl-fixed-header {
  .navbar .nav li.dropdown.active:hover > .dropdown-toggle {
    color: $white;
  }
}

// breadcrumbs
#s-lc-public-bc,
#s-la-bc,
#s-lib-bc {
  a {
    @include border-bottom-link("!important");

    font-family: $font-family-base;
    .font-family-sans-serif-loaded & {
      font-family: $font-family-sans-serif !important;
    }
    &:hover {
      border-color: $spring-green !important;
    }
  }
}

#s-lc-public-bc .breadcrumb,
#s-lib-bc .breadcrumb,
#s-la-bc {
  font-size: 0.85em;
}

div[id^="s-lib-panel-container-collapse"] {
  a {
    word-break: break-all;
    color: $dart-green !important;
    &:hover {
      color: $dart-green !important;
      text-decoration-color: $spring-green !important;
    }
  }
  .alert {
    background-color: $off-white !important;
  }
}

// research guides body
body.s-lib-public-body,
body.s-lg-guide-body {
  padding: 0 !important;
}

#s-lib-bc ol#s-lib-bc-list.breadcrumb {
  margin-top: 16px;
}

// search button needs to be green
.search--dcl-unified-search-form .search-submit {
  background-color: $dart-green !important;
}

#block-bootstrap-dcl-utilitymenu ul.dropdown-menu {
  overflow: visible !important;
}

#s-lg-guide-search-form .input-group-btn button {
  border: 1px solid $dart-green;
}

// background gradients and borders
.s-la-color-bars.s-la-color-bars-border,
.s-la-public-footer {
  border: none !important;
  background-color: none !important;
  background-image: none !important;
}

// misc links
a.bold,
a div.bold {
  @include border-bottom-link;

  display: inline;
  &:hover {
    text-decoration: none !important;
  }
}
a[id^="collapse"]:hover {
  text-decoration: none !important;
  border-bottom: none !important;
}

// libanswers title bar
#s-la-page-title-bar {
  border: 1px solid $gray-medium;
  .navbar-form .form-control {
    width: inherit;
  }
  .navbar {
    > div {
      float: left;
      .navbar-form {
        margin: inherit 0 !important;
      }
      ul.navbar-nav {
        margin: 7.5px -15px !important;
      }
      ul.s-la-navbrowse {
        float: right;
        margin-top: math.div($base-font-size, 2);
      }
    }
    .navbar-text {
      color: $black;
    }
    .s-la-content-search input {
      width: 100%;
    }
    button.navbar-toggle {
      float: right;
    }
  }
  #s-la-browsebar-collapse {
    width: 98%;
  }
}

#s-la-page-maincontent {
  a {
    @include border-bottom-link("!important");
    &:hover {
      border-bottom-color: $spring-green !important;
    }
  }
}
.dcl-s-la-header {
  padding: 0 !important;
}
.dcl-s-la-header,
.dcl-s-la-footer {
  a {
    text-decoration: none !important;
  }
}

// guide title

#s-lg-guide-header-info {
  margin-top: 20px;
  padding-bottom: math.div($base-font-size, 2);
  border-bottom: 1px solid $gray-medium;
  font-family: $font-family-base;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif;
  }
  h1 {
    font-family: $font-family-base;
    .font-family-sans-serif-loaded & {
      font-family: $font-family-sans-serif;
      font-size: $base-font-size * 3;
    }
    #s-lg-guide-name-editable {
      letter-spacing: normal;
      line-height: normal;
    }
  }
}

// libcal skip link
#s-lc-public-skiplink {
  top: -50px;
}
h1.s-lc-c-erh {
  font-size: 36px;
}
#s-lc-c-sbtn {
  padding: 12px;
}

// libcal main content
#s-lc-page-maincontent {
  font-family: $font-family-base;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif !important;
  }
  h1 {
    margin-top: 0;
  }
  a {
    @include border-bottom-link("!important");
    &.s-lc-content-callist-browseby-a {
      border-bottom-width: 1px !important;
      border-bottom-color: #ccc !important;
    }
  }
  .btn.btn-info {
    background-color: $dart-green;
    &:hover {
      color: $white !important;
      background-color: $black !important;
    }
  }
  #s-lc-event-desc {
    * {
      font-family: $font-family-base;
      font-size: 1em !important;

      .font-family-sans-serif-loaded & {
        font-family: $font-family-sans-serif !important;
      }

      * {
        font-family: $font-family-base;
        font-size: 1em !important;

        .font-family-sans-serif-loaded & {
          font-family: $font-family-sans-serif !important;
        }
      }
    }
  }
  .s-lc-event-dl {
    color: $white !important;
    background-color: $dart-green !important;
  }
}
#s-lc-public-main {
  h3 a {
    @include border-bottom-link("!important");
  }
  a {
    @include border-bottom-link("!important");

    &:hover {
      border-color: $spring-green !important;
    }
    &.s-lc-item-more-info,
    &#s-lc-c-clear {
      border: none !important;
    }
    &#s-lc-c-clear {
      margin-bottom: 9px;
    }
  }

  div[id^="bs-select"] a {
    border: none !important;
    &:hover {
      border: none !important;
    }
    &.active {
      color: $white !important;
      background-color: $dart-green;
    }
  }
  #s-lc-c-list-results {
    .s-lc-c-evt {
      .s-lc-event-category-color {
        display: inline-block;
        width: 18px;
        height: 18px;
        vertical-align: middle;
      }
      a.btn {
        color: $white !important;
        border: none !important;
        background-color: $dart-green !important;
        &:hover {
          background-color: $black !important;
        }
      }
    }
  }

  a#nav-da,
  a#nav-ma {
    color: $dart-green !important;
  }

  .datepicker-months .month.active {
    background-color: $dart-green !important;
    background-image: none !important;
  }

  .s-lc-mc-evt a {
    @include border-bottom-link("!important");
  }

  #s-lc-rm-right {
    .popover {
      max-width: 400px !important;
    }
  }
}

#s-lib-public-main {
  .nav.nav-pills li {
    button {
      color: $dart-green !important;
    }
    &.active {
      button {
        color: $white !important;
        background-color: $dart-green !important;
      }
    }
  }
  a {
    @include border-bottom-link("!important");
    &:hover {
      border-color: $spring-green !important;
    }
  }
  .s-srch-resultlist {
    .s-srch-result,
    .s-srch-result-title {
      font-size: $base-font-size !important;
    }
    .pagination {
      .active {
        a {
          color: $white !important;
        }
      }
    }
  }
}

.s-lib-profile-container {
  padding: 9px;
}

#s-lc-public-appm-pt {
  font-size: 36px;
}

// libguides edit buttons
#s-lg-tabs-container {
  .s-lib-box-content ul {
    > li {
      &::before {
        content: "" !important;
      }
    }
  }
}

// libguides profile email button
#s-lib-public-main .s-lib-profile-div.s-lib-profile-email a,
#s-lg-guide-main .s-lib-profile-div.s-lib-profile-email a,
.s-lib-profile-div.s-lib-profile-email a {
  color: $white !important;
  background-color: $dart-green !important;
  font-size: 0.9em !important;
  &:hover {
    background-color: $black !important;
  }
}

// profile widget button
.s-lib-profile-widget {
  button {
    &:hover {
      color: $white !important;
      background-color: $forest-green !important;
    }
  }
}

// libguides main content
#s-lg-guide-main {
  font-family: $font-family-base;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif;
  }

  // add breathing room to content
  div[class*="col-md-"] .s-lib-box {
    max-width: 90%;
    margin-bottom: 40px;
  }
  div.col-md-12 .s-lib-box {
    max-width: 100%;
  }

  #s-lg-col-1 {
    font-size: 1em !important;
  }

  .s-lib-box {
    .s-lib-box-title {
      padding: 0 !important;
      font-family: $font-family-base;
      font-size: 1.25em !important;
      font-weight: bold !important;
      .font-family-sans-serif-loaded & {
        font-family: $font-family-sans-serif;
      }
    }
  }
  a {
    @include border-bottom-link("!important");
  }
  .s-lg-label-more {
    display: inline !important;
  }
  input[type="submit"] {
    @include dart-button;
  }

  ul.s-lg-link-list {
    @include ul-style;
  }

  // tabs and other jQuery UI overrides
  .s-lib-jqtabs > .nav-tabs > li {
    margin: 0 3px 3px 0;
    border-width: 1px;
    border-color: $gray-medium !important;
    border-radius: 0 !important;
    background-color: $dart-green !important;

    &.active {
      border-bottom: none !important;
      background-color: $light-blue;
    }

    &:hover {
      background-color: $forest-green !important;

      a {
        color: $white !important;
        border-color: transparent !important;
        outline-color: transparent !important;
        background-color: $forest-green !important;
      }
    }

    a {
      color: $white !important;
      border-bottom: none !important;
    }
  }

  .s-lib-jqtabs > .nav-tabs > .active {
    color: $black !important;
    border: 1px solid $gray-medium;
    border-bottom: 2px solid $white !important;
    border-radius: 0 !important;
    background-color: $light-blue !important;
    background-image: none !important;
    a {
      margin: 0 3px -1px 0;
      color: $black !important;
      border: none;
      border-radius: 0;
      background: transparent;
    }
  }

  .s-lib-jqtabs > .nav-tabs {
    border: none !important;
    border-bottom: 1px solid $gray-medium !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    background-image: none !important;
  }

  .s-lib-jqtabs .tab-content {
    border: 1px solid $gray-medium !important;
    border-radius: 0 !important;
    .s-lib-jqtabs .tab-content {
      border: none !important;
    }
  }
}

// nav for libguides
#s-lg-side-nav-content {
  font-family: serif;
  .font-family-serif-loaded & {
    font-family: $font-family-serif;
  }
}

// background for nav
.s-lg-tabs-side {
  #s-lg-tabs-container {
    .pad-right-none,
    .pad-left-none {
      padding: 10px;
    }
    .nav-pills li {
      background-color: $college-background-gray;
      a {
        width: 100%;
        color: $black !important;
        &:hover {
          color: $white !important;
          background-color: $dart-green !important;
          i.fa {
            color: $white;
          }
        }
      }
    }
    .nav-stacked > li + li {
      margin: 0;
    }
    .nav-pills > li > ul.s-lg-subtab-ul,
    .nav-pills > li > ul.s-lg-boxnav {
      margin-left: 0;
      li {
        margin: 0;
        padding: 0;
        border-radius: 0;
        a {
          display: inline-block;
          width: 100%;
          padding: 6px 0 6px 27px;
          color: $black !important;
          line-height: normal;
          &:hover {
            color: $white !important;
            background-color: $dart-green !important;
            i.fa {
              color: $white;
            }
          }
        }
      }
    }
  }

  .s-lib-box,
  .s-lib-box-title {
    background-color: $off-white;
  }
}

// search button for libanswers
#s-la-page-title-bar .s-la-searchform-button {
  @include dart-button;
  &:hover {
    background-color: $black !important;
  }
}

.s-lib-box-content {
  ul {
    @include ul-style;
    &.nav-tabs {
      li::before {
        content: "" !important;
      }
    }
    &.list-unstyled {
      margin-left: $base-font-size !important;
    }
    &.list-inline {
      li::before {
        content: "" !important;
      }
    }
  }

  input[type="submit"] {
    @include dart-button;
  }

  .s-lib-jqtabs > .nav-tabs {
    font-family: $font-family-base;
    .font-family-sans-serif-loaded & {
      font-family: $font-family-sans-serif !important;
    }
  }
}

// see _menu.scss .region-sidebar-first
// #s-lg-guide-tabs, wrapper of the left sidebar in libguides, including top left navigation + other content added to the left sidebar
#s-lg-guide-tabs {
  margin-bottom: $base-font-size;

  // applies to all left sidebar
  border-collapse: collapse;
  font-family: $font-family-base;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif;
  }
  a {
    @include border-bottom-link;
  }

  // .nav-pills, wrapper for the left navigation menu in the left sidebar
  .nav-pills > li > a,
  .nav-pills > .active > a {
    background-color: transparent !important;
  }

  .nav-pills > li > a {
    font-weight: normal !important;
  }

  .nav-pills > .active > a {
    background-color: $spring-green !important;
    font-family: $font-family-base;
    font-weight: bold;
  }
  h1,
  h2,
  h3 {
    margin: 0;
    padding-top: 10px;
    padding-right: $base-font-size;
    padding-bottom: 10px;
    border-bottom: 1px solid $gray-medium;
    font-family: $font-family-base;
    font-size: em($base-font-size * 1.15);
    font-weight: normal;
    .font-family-sans-serif-loaded & {
      font-family: $font-family-sans-serif !important;
    }
    &:first-of-type {
      margin-top: 20px;
      border-top: 1px solid $gray-medium;
    }
    a {
      color: $black !important;
    }
  }

  h1 {
    line-height: inherit !important;
  }

  // seems to be only used within .nav-pills, the left navigation menu
  ul {
    @extend .menu;

    list-style: none !important;
  }

  ul.nav-pills {
    font-family: $font-family-base;
    .font-family-sans-serif-loaded & {
      font-family: $font-family-sans-serif !important;
    }

    // overrding springshare menu style
    strong,
    b {
      font-weight: normal;
    }
    .active strong,
    .active b {
      font-weight: $font-weight-strong;
    }
  }

  // applies to all li items in left sidebar CHANGED TO JUST THE LEFT NAV MENU
  .nav-pills > li {
    padding: 0;
    border-bottom: 1px solid $gray-medium !important;
    a {
      color: $dart-green !important;
      &:hover {
        outline-color: transparent;
        background-color: transparent !important;
      }
    }

    // add caret to parent menu items that are closed to indicate parent status
    &.dropdown a.s-lg-tab-top-link::after {
      @extend %icon-arrow-drop-down;
      @extend %icon-style;

      float: right;
    }

    // nested items
    ul.s-lg-boxnav,
    ul.s-lg-subtab-ul {
      font-size: $base-font-size * 0.9 !important;
      li {
        border-bottom: none !important;
      }
      a {
        padding: 3px 0;
        border-bottom: none !important;
      }
    }

    // applies to all a links in left sidebar, within li
    a {
      color: $dart-green !important;
      border-collapse: collapse;
    }
  }

  ul[id^="s-lg-link-list"] li div[id^="s-lg-content"] {
    a {
      @include border-bottom-link("!important");

      color: $dart-green !important;
      &:hover {
        border-bottom: 1px solid $light-blue !important;
      }
    }

    a.s-lg-label-more {
      display: inline !important;
    }
    div[id^="s-lg-icon"] li {
      border-bottom: none !important;
    }
  }

  ul.nav-tabs {
    padding: 1px 0;
    border-bottom: 1px solid $gray-medium;
    li {
      a {
        color: $white !important;
        font-weight: normal !important;
      }
      &.active {
        a {
          color: $forest-green !important;
          border-bottom: 1px solid transparent;
          background-color: $light-blue;
        }
      }
      &:hover {
        a {
          color: $white !important;
          background-color: $forest-green !important;
        }
      }
    }
  }

  .dropdown-menu {
    right: 1px !important;
    > li > a {
      text-align: right !important;
    }
  }
}

// overriding libguides menu style
.s-lg-tabs-side .s-lg-boxnav .list-group-item {
  border: none;
  background-color: $white;
}

#s-lg-tabs-container .nav-pills > li > a {
  &.s-lg-tab-top-link,
  &.s-lg-tab-top-link:hover {
    padding: 10px 15px;
  }
  &.s-lg-tab-drop,
  &.s-lg-tab-drop:hover {
    display: none;
  }
}

// remove hover effect
#s-lg-guide-tabs ul.nav li.dropdown a.s-lg-tab-drop:hover ~ ul.dropdown-menu,
#s-lg-guide-tabs ul.nav li.dropdown ul.dropdown-menu:hover,
#s-lg-guide-tabs ul.nav li.dropdown:hover ul.dropdown-menu {
  display: none;
}

// springshare footer
#s-lc-public-footer,
#s-la-public-footer,
#s-lib-footer-public {
  color: $black !important;
  border-top: none !important;
  background-color: transparent !important;
  font-family: $font-family-sans-serif !important;
  font-size: 0.75em !important;
  > div {
    margin: 4px 0;
  }
  a {
    @include border-bottom-link("!important");

    &:hover {
      border-color: $spring-green !important;
    }
  }
}

.s-lib-footer-login-link {
  float: none !important;
}

// admin toolbar
.s-lg-guide-body .s-lib-cmd-bar,
.s-lib-admin-body .navbar {
  position: static !important;
  width: 100% !important;
  margin-top: -80px;
}
#s-lib-admin-header-desc:empty,
.s-lib-cmd-bar .container {
  width: auto !important;
}

#s-lg-public-skiplink {
  top: -50px;
}

// libguides scrolltop
#s-lib-scroll-top {
  bottom: 10%;
  font-size: $base-font-size;
  a {
    padding: 9px 0;
    color: $black;
    border: 1px solid $white;
    border-radius: 4px;
    background-color: $spring-green;
    &:hover {
      border-color: $spring-green;
      background-color: $black;
      .fa {
        color: $white;
      }
    }
    .fa-stack {
      width: 36px;
    }
    .fa-angle-double-up {
      &::before {
        @extend %icon-keyboard-arrow-up;
      }
    }
  }
  .fa-square-o {
    display: none;
  }
}

// datepicker
#s-lc-rm-cal {
  .ui-datepicker {
    width: auto !important;
    .ui-datepicker-header {
      font-size: 0.75em !important;
    }
    table {
      font-size: 0.8em !important;
    }
  }
}

// booking
.s-lc-app-booking-header {
  .s-lc-app-location-select {
    clear: both;
    .bootstrap-select {
      width: auto !important;
      .dropdown-toggle .filter-option-inner-inner,
      .bs-caret {
        color: $white;
        background-color: $dart-green;
      }
      button {
        &:hover,
        &:focus {
          background-color: $dart-green;
        }
      }
      button,
      .dropdown-menu {
        background-color: $dart-green;
        font-size: $base-font-size;
      }
      .dropdown-menu li a {
        color: $white;
        &.active {
          color: $black;
          background-color: $spring-green;
          &:hover,
          &:focus {
            color: $black;
            background-color: $spring-green;
          }
        }
        &:hover,
        &:focus {
          color: $white;
          background-color: $forest-green;
        }
      }
    }
  }
}

// booker
#s-lc-rm-tg-rnames {
  font-size: 14px !important;
}

.lc_rm_a {
  color: $dart-green !important;
  background-color: $dart-green !important;
}

.lc_rm_t {
  background-color: $gold-accent !important;
}

.label-rm-avail {
  background-color: $dart-green !important;
}

#s-lc-rm-form-box {
  #s-lc-rm-sub {
    color: $white !important;
    background-color: $dart-green !important;
    &:hover {
      color: $white !important;
      background-color: $black !important;
    }
  }
}

// inputs
input[type="text"] {
  background-color: $off-white !important;
}

// INLINE inline styles added to springshare
// overriding link style as border-bottom for readability in the guides
#s-lg-guide-main a,
#s-lg-guide-main .result .more-toggle,
.result #s-lg-guide-main .more-toggle {
  &:hover {
    border-color: $spring-green !important;
  }
}

#s-lg-col-1 {
  font-size: 0.9em !important;
  &.col-md-12 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
}

// END INLINE inline styles added to springshare

@media (max-width: em(990px)) {
  body .container {
    width: 100% !important;
  }

  #s-lg-guide-tabs {
    text-align: left;
  }
}

@media (max-width: em(767px)) {
  .block-dcl-emergency-banner {
    font-size: 1em;
  }
  #s-la-page-title-bar {
    .navbar-header {
      width: 100%;
    }
  }
}

// scroll to top
#s-lib-scroll-top {
  border-radius: 4px;
  background-color: $spring-green !important;
  &:hover,
  &:focus {
    color: $white;
    background-color: $forest-green;
  }
}

// Bootstrap 5 fixes
.dcl-fixed-header {
  margin: 0;
  padding: 0;
  > .dcl-affix {
    width: 100%;
    > nav.navbar {
      padding: 0;
    }
  }
  .menu--global-dropdown-menu {
    > li > a.dropdown-toggle {
      white-space: normal;
      &::after {
        display: none;
      }
    }
  }
}

footer {
  div.container {
    max-width: 100%;
  }
  ul.second-level-menu {
    margin-top: 2px;
    padding-left: 15px;
  }
  ul.menu--footer-library-links,
  ul.menu--footer-about-us,
  ul.menu--footer-copyright {
    display: block;
  }
}
