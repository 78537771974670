// @file _colors.scss

// Menus
// ----------

header {
  .library-utility-wrapper {
    background-color: $dart-green;
  }
  .search-text {
    color: $off-white;
  }
}

body {
  color: $gray-dark;
}

// bootstrap pager color override
.pagination > .active > a,
.result .pagination > .active > .more-toggle,
.pagination > .active > a:hover,
.result .pagination > .active > .more-toggle:hover,
.pagination > .active > a:focus-visible,
.result .pagination > .active > .more-toggle:focus-visible,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus-visible {
  background-color: $dart-green;
}
