// @file _utiliy.scss

.row-neg-margin {
  margin-right: -15px;
  margin-left: -15px;
}

.aspect-medium-portrait-with-text {
  display: grid;
  align-items: center;
  aspect-ratio: 16/9;
  margin: 10px 0;
  padding: 1rem;
  text-align: center;
  color: $white;
  background-color: $gray-intro;
  font-size: 2rem;
  &.with-icon {
    p::before {
      @extend %icon-user;

      display: block;
      font-size: 8rem;
    }
  }
}

.dcl-portraits img {
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center 10%;
}

.mobile-label {
  display: none;
}

@media (max-width: 767px) {
  span.mobile-label {
    display: inline;
  }
}
