// @file
// Style for ordered and unordered lists.

.dcl-content-region {
  nav ul,
  ul.nav {
    li::before {
      content: "";
    }
  }
}
