// @file
// Grid layout for header, main, and footer.

// header
.primary-secondary-menus-inner-wrapper {
  @include dcl-container;
}

// main content
.main-container.container {
  @include dcl-container;

  margin-top: 20px;
}

.region-content .content {
  padding: 0 20px 0 5px;
}

.dcl-content-region {
  float: right;
  margin-top: 10px;
}

.region-sidebar-feature {
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

.dcl-sidebar-first {
  margin-bottom: 2rem;
}

@media (max-width: em(767px)) {
  .dcl-content-region {
    float: none;
  }
}

@media (min-width: em(768px)) and (max-width: em(992px)) {
  .main-container.container {
    width: 100% !important;
  }
}

.navbar.container {
  max-width: 1500px;
}

.navbar-collapse {
  float: right;
}

// Using Paragraphs module for layout
.page-alias-about.page-node-type-landing-page // about landing page
.para__layout {
  @extend .row;

  .para__layout__field__2:first-of-type {
    @include make-sm-column(7);
    @include make-xs-column(12);
  }

  .para__layout__field__2:last-of-type {
    @include make-sm-column(5);
    @include make-xs-column(12);
  }
}

.offscreen {
  position: absolute;
  top: auto;
  left: -10000px;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

// footer styling
body footer {
  width: 100%;
  > .container {
    @include dcl-container;

    padding: 0 30px;
  }
}

@media (min-width: em(768px)) {
  footer .container {
    width: 750px;
  }
}

@media (min-width: em(992px)) {
  footer .container {
    width: 970px;
  }
}

@media (min-width: em(1200px)) {
  footer .container {
    width: 1170px;
  }
}
