// @file
// Style for site breadcrumbs.

.breadcrumb {
  margin-top: 12px;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
  letter-spacing: 0.1px;
  background-color: transparent;
  font-family: $font-family-base;
  font-size: $base-font-size * 0.8;
  font-weight: 200;
  .font-family-serif-loaded & {
    font-family: $font-family-sans-serif;
  }
  > li {
    display: inline;
    &.active {
      color: $gray-intro;
      font-weight: 500;
    }
  }
  > li + li::before {
    @extend %icon-chevron-right;
    @extend %icon-style;

    color: $black;
  }
  a {
    color: $dart-green;
    border-bottom: 0.5px solid $dart-green;

    &:hover,
    &:focus-visible {
      text-decoration: none;
      color: $dart-green;
      border-bottom: 1.5px solid $spring-green;
    }
    &:focus {
      text-decoration: none;
    }
  }
}
