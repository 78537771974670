// icon map
// glyphicons
// search/magnifying glass : \e003
// user : \e008
// home : \e021
// clock : \e023
// bookmark : \e044
// calendar : \e109
// random: : \e110
// globe : \e135
// blackboard : \e218
// filled triangle down : \e252
// filled triangle up : \e253

// material icons
// person : \e7fd

// font awesome
// search/magnifying glass : \f002
// envelope open : \f003
// user : \f007
// remove/close/times : \f00d
// gear/cog : \f013
// chevron left : \f053
// chevron right : \f054
// info circle : \f05a
// arrow left : \f060
// arrow right :\f061
// arrow down : \f063
// warning triangle : \f071
// calendar : \f073
// twitter square : \f081
// comments filled : \f086
// linked in square : \f08c
// external link : \f08e
// phone square : \f098
// facebook : \f09a
// github : \f09b
// rss/feed : \f09e
// globe : \f0ac
// wrench : \f0ad
// chain/link : \f0c1
// rotate left/undo : \f0e2
// sitemap : \f0e8
// building open : \f0f7
// angle double left :\f100
// angle double right : \f101
// angle double up : \f102
// angle double down : \f103
// angle left : \f104
// angle right : \f105
// angle up : \f106
// angle down : \f107
// mobile phone : \f10b
// circle : \f111
// play circle : \f144
// compass : \f14e
// instagram : \f16d
// calendar check : \f274
// pause circle : \f28b

%icon-account-tree {
  @include material-icon("\e97a");
}
%icon-arrow-down {
  @include material-icon("\e984");
}
%icon-arrow-drop-down {
  @include material-icon("\e5c5");
}
%icon-arrow-drop-up {
  @include material-icon("\e5c7");
}
%icon-arrow-left {
  @include material-icon("\ef7d");
}
%icon-arrow-right {
  @include material-icon("\e941");
}
%icon-arrow-up {
  @include material-icon("\e986");
}
%icon-book {
  @include material-icon("\e02f");
}
%icon-bookmark {
  @include material-icon("\e866");
}
%icon-calendar {
  @include material-icon("\ebcc");
}
%icon-calendar-check {
  @include material-icon("\e614");
}
%icon-calendar-add-on {
  @include material-icon("\ef85");
}
%icon-campus-services {
  @include material-icon("\efec");
}
%icon-check-circle {
  @include material-icon("\e86c");
}
%icon-chevron-left {
  @include material-icon("\e5cb");
}
%icon-chevron-left-double {
  @include material-icon("\eac3");
}
%icon-chevron-right {
  @include material-icon("\e5cc");
}
%icon-chevron-right-double {
  @include material-icon("\eac9");
}
%icon-circle {
  @include material-icon("\ef4a");
}
%icon-clock {
  @include material-icon("\e8b5");
}
%icon-close-times-remove {
  @include material-icon("\e5cd");
}
%icon-cloud {
  @include material-icon("\e2bd");
}
%icon-close-fullscreen {
  @include material-icon("\f1cf");
}
%icon-comments {
  @include material-icon("\e0bf");
}
%icon-compass {
  @include material-icon("\e87a");
}
%icon-contact-page {
  @include material-icon("\f22e");
}
%icon-copyright {
  @include material-icon("\e90c");
}
%icon-dot {
  content: "\25cf";
}
%icon-edit {
  @include material-icon("\e745");
}
%icon-email {
  @include material-icon("\e0e6");
}
%icon-expand-less {
  @include material-icon("\e5ce");
}
%icon-expand-more {
  @include material-icon("\e5cf");
}
%icon-expand-screen {
  @include material-icon("\f830");
}
%icon-facebook {
  @include fontawesome-icon("\f39e");
}
%icon-gear-cog {
  @include material-icon("\e8b8");
}
%icon-github {
  @include fontawesome-icon("\f09b");
}
%icon-globe {
  @include material-icon("\e64c");
}
%icon-groups {
  @include material-icon("\f233");
}
%icon-home {
  @include material-icon("\e88a");
}
%icon-info {
  @include material-icon("\e88e");
}
%icon-info-i {
  @include material-icon("\f59b");
}
%icon-instagram {
  @include fontawesome-icon("\f16d");
}
%icon-keyboard-arrow-up {
  @include material-icon("\e316");
}
%icon-label {
  @include material-icon("\e892");
}
%icon-location-on {
  @include material-icon("\e0c8");
}
%icon-link {
  @include material-icon("\e89e");
}
%icon-linkedin {
  @include fontawesome-icon("\f0e1");
}
%icon-lock-open {
  @include material-icon("\e898");
}
%icon-mail-envelope {
  @include material-icon("\e158");
}
%icon-manage-search {
  @include material-icon("\f02f");
}
%icon-menu {
  @include material-icon("\e5d2");
}
%icon-north {
  @include material-icon("\f1e0");
}
%icon-phone-call {
  @include material-icon("\e0b0");
}
%icon-pause-circle {
  @include material-icon("\e1a2");
}
%icon-phone-mobile {
  @include material-icon("\e325");
}
%icon-pin-drop {
  @include material-icon("\e55e");
}
%icon-play-circle {
  @include material-icon("\e1c4");
}
%icon-quote {
  @include material-icon("\e244");
}
%icon-random {
  @include material-icon("\e043");
}
%icon-rss-feed {
  @include material-icon("\e0e5");
}
%icon-reset {
  @include material-icon("\f053");
}
%icon-search {
  @include material-icon("\e8b6");
}
%icon-share {
  @include material-icon("\e80d");
}
%icon-spinner {
  @include fontawesome-icon("\f110");
}
%icon-styleTag {
  @include material-icon("\e41d");
}
%icon-tag {
  @include material-icon("\f05b");
}
%icon-top-right {
  @include material-icon("\f72d");
}
%icon-twitter {
  @include fontawesome-icon("\e61b");
}
%icon-user {
  @include material-icon("\e7fd");
}
%icon-volume-down {
  @include material-icon("\e04d");
}
%icon-volume-mute {
  @include material-icon("\e710");
}
%icon-volume-off {
  @include material-icon("\e04e");
}
%icon-volume-up {
  @include material-icon("\e050");
}
%icon-warning {
  @include material-icon("\e002");
}
%icon-wrench {
  @include material-icon("\e869");
}
%icon-youtube {
  @include fontawesome-icon("\f16a");
}

.bi-volume-mute-fill::after {
  @extend %icon-volume-mute;
  @extend %icon-style;
}
.bi-volume-off-fill::after {
  @extend %icon-volume-off;
  @extend %icon-style;
}
.bi-volume-down-fill::after {
  @extend %icon-volume-down;
  @extend %icon-style;
}
.bi-volume-up-fill::after {
  @extend %icon-volume-up;
  @extend %icon-style;
}

.icon-arrow-right::after {
  @extend %icon-arrow-right;
  @extend %icon-style;
}
.icon-book::after {
  @extend %icon-book;
  @extend %icon-style;
}
.icon-calendar::after {
  @extend %icon-calendar;
  @extend %icon-style;
}
.icon-calendar-add-on::after {
  @extend %icon-calendar-add-on;
  @extend %icon-style;
}
.icon-check-circle::after {
  @extend %icon-check-circle;
  @extend %icon-style;
}
.icon-chevron-left-double::after {
  @extend %icon-chevron-left-double;
  @extend %icon-style;
}
.icon-chevron-right-double::after {
  @extend %icon-chevron-right-double;
  @extend %icon-style;
}
.icon-clock::after {
  @extend %icon-clock;
  @extend %icon-style;
}
.icon-close-fullscreen::after {
  @extend %icon-close-fullscreen;
  @extend %icon-style;
}
.icon-close-times-remove::after {
  @extend %icon-close-times-remove;
  @extend %icon-style;
}
.icon-cloud::after {
  @extend %icon-cloud;
  @extend %icon-style;
}
.icon-contact-page::after {
  @extend %icon-contact-page;
  @extend %icon-style;
}
.icon-copyright::after {
  @extend %icon-copyright;
  @extend %icon-style;
}
.icon-edit::after {
  @extend %icon-edit;
  @extend %icon-style;
}
.icon-expand-screen::after {
  @extend %icon-expand-screen;
  @extend %icon-style;
}
.icon-expand-less::after {
  @extend %icon-expand-less;
  @extend %icon-style;
}
.icon-expand-more::after {
  @extend %icon-expand-more;
  @extend %icon-style;
}
.icon-facebook::after {
  @extend %icon-facebook;
  @extend %icon-style;
}
.icon-groups::after {
  @extend %icon-groups;
  @extend %icon-style;
}
.icon-info::after {
  @extend %icon-info;
  @extend %icon-style;
}
.icon-info-i::after {
  @extend %icon-info-i;
  @extend %icon-style;
}
.icon-instagram::after {
  @extend %icon-instagram;
  @extend %icon-style;
}
.icon-location-on::after {
  @extend %icon-location-on;
  @extend %icon-style;
}
.icon-linkedin::after {
  @extend %icon-linkedin;
  @extend %icon-style;
}
.icon-mail-envelope::after {
  @extend %icon-mail-envelope;
  @extend %icon-style;
}
.icon-menu::after {
  @extend %icon-menu;
  @extend %icon-style;
}
.icon-phone-call::after {
  @extend %icon-phone-call;
  @extend %icon-style;
}
.icon-phone-mobile::after {
  @extend %icon-phone-mobile;
  @extend %icon-style;
}
.icon-share::after {
  @extend %icon-share;
  @extend %icon-style;
}
.icon-spinner::after {
  @extend %icon-spinner;
  @extend %icon-style;
}
.icon-twitter::after {
  @extend %icon-twitter;
  @extend %icon-style;
}
.icon-warning::after {
  @extend %icon-warning;
  @extend %icon-style;
}
.icon-youtube::after {
  @extend %icon-youtube;
  @extend %icon-style;
}

.icon-margin-left {
  margin-left: 4px;
}

.icon-margin-right {
  margin-right: 4px;
}
