// @file
// Style for library logo header, department banner image/content.
// Bootstrap navbar area.

.skip-link {
  display: inline-block;
  margin: 6px;
}

.dcl-fixed-header {
  z-index: 4;
  width: auto;
  background-color: transparent;
}

.page-header {
  margin: 0;
  padding-bottom: 5px;
  border-bottom: none !important;
}

.block-system-branding-block {
  align-self: center;
  padding-bottom: 10px;
  &.custom-department-logo {
    a img {
      max-width: unset;
      height: unset;
    }
  }
  a {
    display: block;
    text-decoration: none !important;
    color: $dart-green !important;
    &:hover {
      color: $dart-green !important;
      border: none !important;
    }
    img {
      width: $base-font-size * 8;
      &.mobile-site-icon {
        display: none;
      }
      &.dcl-scrolled {
        width: 40px;
        margin-top: 5px;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
      }
    }
  }
}

.mobile-branding {
  float: left;
  margin-top: -8px;
  margin-bottom: 0;
  color: $white;
  font-family: serif;
  .headings-font-family-h1-loaded & {
    font-family: $headings-font-family-h1;
  }
  .mobile-site-name {
    font-size: 1.25em;
  }
  a {
    text-decoration: none !important;
  }
  a:hover {
    text-decoration: none !important;
    border-bottom: 1px solid $off-white !important;
  }
}

.dcl-fixed-header {
  header {
    padding-bottom: 0;
  }
}

.primary-secondary-menus .mobile-branding {
  margin-left: -10px;
}

#block-unifiedsearchform {
  padding-right: 0;
}

.navbar {
  border: none;
  border-bottom: none;
  border-radius: 0;
  background-color: transparent;
}

.btn-nav-dartmouth {
  margin-bottom: 5px !important;
  font-size: em($base-font-size * 0.875);
  a {
    color: $dart-green !important;
  }
}

@media (max-width: em(991px)) {
  .block-system-branding-block a img {
    width: $base-font-size * 5;
  }
}

@media (max-width: em(767px)) {
  .region-primary-menu,
  .region-primary-menu.dcl-scrolled {
    margin-top: -8px !important;
    margin-bottom: 0 !important;
    padding: 2px !important;
  }

  .header-site-name {
    display: none;
  }

  .mobile-branding {
    display: none;
  }
  .collapsed.navbar-toggle.mobile-branding > a {
    font-size: 1.5em;
  }

  #block-bootstrap-dcl-branding {
    color: $white;
    font-size: em($base-font-size * 1.5);
  }

  #block-unifiedsearchform {
    padding-left: 0;
  }
  .block-system-branding-block a img,
  .block-system-branding-block a img.dcl-scrolled {
    padding-top: 10px !important;
    &.mobile-site-icon {
      display: inline;
      width: unset;
      height: $base-font-size * 2.75;
    }
    &.desktop-site-icon {
      display: none;
    }
  }
}
