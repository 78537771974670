// @file
// Style for menu items: positions, link styles.

// grid layout for menu items to allow grid layout of menu
// items. integrate with above if needed set desired/max rows and
// columns
$dcl-nav-menu-rows: 4;
$dcl-nav-menu-columns: 3;

.library-top-bar {
  a {
    color: $black;
    font-size: 110%;
  }
}

#dept-menu {
  padding: 5px 15px;
  li {
    border-bottom: 1px dotted $off-white;
  }
  a {
    color: $black;
  }
}

#dept-info {
  border-top: 3px solid $yellow;
  background-color: $off-white;
  h4 {
    margin-left: 2rem;
  }
  ul {
    list-style: none;
    li.address {
      margin: 1.5rem 0;
      p {
        margin: 0;
        padding: 0;
      }
    }
    li.social-links {
      margin: 1.5rem 0;
      a {
        text-indent: -9999px;
      }
      a[href^="https://www.facebook.com"]
        ::before {
        @extend %icon-facebook;

        width: 35px;
      }
      a::before {
        float: left;
        text-align: center;
        text-indent: 0;
        color: $gray;
        background: $yellow;
        font-size: 1.5rem;
      }
    }
  }
}

// Above here is old and can probably be removed
// Bootstrap DCL

// admin menus
#toolbar-administration {
  z-index: 10000;
}

.navbar-top-menu {
  border: none !important;
}

.dropdown-menu > li > a,
.result .dropdown-menu > li > a.more-toggle {
  &:focus,
  &:hover {
    background-color: transparent;
  }
}

// the wrapper for the green bar in the banner
.primary-secondary-menus,
.region-mobile-menu {
  width: 100%;
  padding: 0 !important;
  background-color: $white;
  a.dropdown-toggle,
  span.dropdown-toggle {
    color: $black;
    font-size: $base-font-size;
    &:hover,
    &:focus-visible {
      color: $white;
      background-color: $forest-green;
      > .icon-tab-toggle {
        color: $white;
      }
    }
  }
  li.hover {
    > a,
    > span,
    > button {
      color: $white;
    }
  }
  span.dropdown-toggle {
    &::after {
      @extend %icon-chevron-right;

      margin-left: 4px;
      vertical-align: sub;
    }
  }
  a.dropdown-toggle {
    &:hover,
    &:focus-visible,
    &:active {
      text-decoration: underline;
    }
  }
  ul.contextual-links {
    a {
      color: $gray-dark;
    }
    a:hover,
    a:focus-visible,
    a:active {
      text-decoration: underline;
      background-color: $teal;
    }
  }
}

.primary-secondary-menus .primary-secondary-menus-inner-wrapper {
  position: relative;
  width: 95%;
  max-width: 1500px;
  min-height: 85px;
  padding-top: 0;
  padding-bottom: 0;
}

#primary-secondary-menus-wrapper-mobile {
  overflow-y: scroll;
  background-color: $dart-green;
  -webkit-overflow-scrolling: touch;
  .mobile-branding {
    width: 90%;
    margin: 10px 0 0 10px;
  }
  ul.nav.menu {
    li {
      text-align: left !important;
    }
  }
  .region-secondary-menu ul li a {
    padding-left: 5px;
  }
  a {
    color: $black !important;
  }
  a:hover,
  a:active,
  a:focus-visible {
    border-color: $black !important;
    background-color: transparent !important;
  }
}

.library-utility-menu {
  li {
    float: left;
    a {
      color: $black;
      font-size: 110%;
    }
  }
}

#primary-secondary-menus-wrapper {
  width: 100%;
  margin: 0;
  padding-bottom: $base-font-size * 0.5;
}

// Make the bars for the toggle white
.navbar-toggle .icon-bar {
  background-color: $black;
}

// fix for the wrapped divs which have a display:block so that we can display navs in one line
.region-primary-menu,
.region-primary-menu nav,
.region-secondary-menu,
.region-secondary-menu nav {
  display: inline;
}

// make global nav menu display properly even when logged in
.region-primary-menu nav.contextual-region {
  position: static;
}

// primary-menu is the set of left hand links
// secondary menu is the right hand menu including the account dropdown

#block-mainnavigation-2 .nav,
#block-mainnavigation-3 .nav,
.region-primary-menu .nav {
  &.navbar-nav > li {
    a {
      text-transform: uppercase;
      color: $white;
      font-size: em($base-font-size);
    }
  }
}

#block-utilitymenu-3 {
  font-size: em($base-font-size);
}

.navbar-btn {
  margin-top: -16px;
}

.region-primary-menu {
  display: grid;
  grid-template-rows: fit-content(100%) 1fr;
  grid-template-columns: fit-content(100%) 1fr fit-content(100%);
  align-content: center;
  width: 100%;
  margin-top: 7px;
  padding: 2px 0;
  gap: $base-font-size * 0.25 $base-font-size * 1.5;
  ul {
    margin-left: -5px !important;
  }
  &.dcl-scrolled {
    margin: -8px 0;
    padding: 0;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }
}

.region-secondary-menu {
  float: right;
  font-size: em($base-font-size * 0.875);
  ul {
    margin-right: 5px !important;
  }
}

.region-primary-menu,
.region-secondary-menu {
  ul.nav.menu {
    li {
      text-align: center;
    }
  }
}

// make the underline a border and account for the additional height when the link is in hover
.region-primary-menu,
.region-secondary-menu,
#primary-secondary-menus-wrapper-mobile {
  ul {
    li {
      padding: 10px !important;
      a {
        padding: 0 !important;
      }
      a:hover,
      a:focus-visible,
      a:active {
        margin-bottom: -1px !important;
        text-decoration: none;
        color: $spring-green;
        border-color: $spring-green;
      }
    }
  }
}

.region-secondary-menu li:last-of-type {
  padding-right: 12px !important;
}

#primary-secondary-menus-wrapper-mobile {
  a {
    display: inline !important;
  }
  #block-unifiedsearchform-2 {
    float: none !important;
  }
  #block-mainnavigation-2,
  #block-utilitymenu-3 {
    ul {
      margin-top: 0 !important;
    }
    ul.menu.dropdown-menu {
      margin-top: 0;
    }
  }
}

#primary-secondary-menus-wrapper-mobile {
  .region-mobile-menu {
    font-size: 1em;
    ul.nav.menu {
      margin: 0 !important;
      li {
        margin: 0 -20px 0 -10px !important;
        text-indent: 20px;
        border-top: 1px solid $gray;
        ul.menu {
          li {
            padding: 5px !important;
            border-top: none;
          }
        }
      }
    }

    #block-utilitymenu-3 ul.nav.menu {
      li {
        ul.menu {
          li {
            padding: 5px !important;
          }
        }
      }
    }
  }
}

#block-utilitymenu-3,
.region-secondary-menu {
  a {
    font-weight: 100;
  }

  a:active,
  a:focus-visible {
    border-color: $white !important;
    background-color: transparent !important;
  }

  // the dropdown needs its own special sauce as does the ul that opens
  .nav .open > a {
    background-color: transparent !important;
  }
  li.expanded.dropdown.open {
    ul.dropdown-menu {
      margin-top: 20px;
      padding: 10px;
      background-color: $dart-green !important;
      li {
        padding: 5px 0 !important;
      }
      a {
        display: inline;
        color: $white !important;
        background-color: transparent !important;
      }
      a:hover,
      a:focus-visible,
      a:active {
        margin-bottom: -1px !important;
        border: none;
        border-bottom: 1px solid $white;
      }
    }
  }
}
.region-secondary-menu {
  li.expanded.dropdown.open {
    // the caret at the top of the expanded dropdown
    ul.dropdown-menu::before {
      @extend %icon-expand-less;

      position: absolute;
      top: -18px;
      right: 25px;
      color: $dart-green;
      font-size: em($base-font-size * 1.5);
    }
  }
}

// closer for mobile menu
.mobile-menu-closer {
  float: right;
  margin-right: -10px;
  margin-bottom: 20px;
  padding: 0 8px 3px;
  color: $off-white;
  border: 1px solid $off-white;
  &:hover {
    cursor: pointer;
  }
}

// add the icons for the right hand links
#block-utilitymenu-3,
.region-secondary-menu {
  ul {
    li {
      a::before {
        margin-right: 3px;
        vertical-align: bottom;
        color: $off-white;
        font-family: $font-primary-icons;
        font-size: em($base-font-size * 0.8);
        font-weight: 100;
      }
      a[href*="libcal"]::before {
        @extend %icon-calendar;
      }
      a[href*="campus"]::before {
        @extend %icon-rss-feed;
      }
      a[href*="accounts"]::before {
        @extend %icon-user;
      }
    }
  }
}

// side menus - navigation
.region-sidebar-first {
  margin-left: 5px;
  border-collapse: collapse;
  h2 {
    width: 100%;
    margin-bottom: 0;
    padding-top: 10px;
    padding-right: $base-font-size;
    padding-bottom: 10px;
    border-top: 1px solid $gray-medium;
    border-bottom: 1px solid $gray-medium;
    font-size: $base-font-size * 1.25;
    &[id^="block-globaldropdownmenu"] {
      justify-self: start;
    }
    a {
      color: $black;
      &:focus-visible {
        outline: solid 3px $yellow;
      }
    }
  }
  .quick-links-menu {
    position: relative;
    display: block;
    padding: 10px 0 10px $base-font-size * 0.25;
    font-size: $base-font-size;
    font-weight: 500;
  }

  ul.dropdown-menu > li > a {
    white-space: normal;
  }

  nav > ul.active-menu > li:not(.active-trail) {
    display: none;
  }

  li {
    border-bottom: 1px solid $forest-green;
    font-size: $base-font-size;
    a {
      padding-left: $base-body-font-size * 0.5;
      letter-spacing: 0.6px;
      color: $black;
      border-bottom: none;
      font-size: $base-font-size;
      font-weight: 500;
      line-height: 130%;
      &:hover {
        outline-color: transparent !important;
      }
      &:hover,
      &:focus-visible {
        color: $white !important;
        background-color: $dart-green;
      }
    }
    &.active {
      background-color: $spring-green;
    }
    &.active:not([class*="active-parent"]) {
      > a {
        color: $white !important;
        background-color: $forest-green;
        &::before {
          @extend %icon-chevron-right;

          vertical-align: bottom;
          font-weight: $font-weight-strong;
        }
      }
    }
    &.expanded ul.dropdown-menu {
      position: static;
      display: inline;
      float: none;
      border: none;
      box-shadow: none;
      li {
        a {
          padding: 9px 9px 9px 24px;
          border-bottom: none !important;
          font-size: $base-font-size * 0.85;
          &:not(.is-active)::after {
            color: transparent;
          }
        }
      }
    }
    &.active {
      li {
        background-color: $college-background-gray;
      }
    }
    a {
      color: $black !important;
      border-collapse: collapse;
    }
  }

  ul.menu--policies {
    li {
      background-color: $college-background-gray;
      a {
        padding: 9px 9px 9px 24px;
        border-bottom: none !important;
        font-size: $base-font-size * 0.85;
        &:not(.is-active)::after {
          color: transparent;
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  // Override bootstrap submenu styles
  .caret {
    display: none;
  }
  .dropdown-menu > .active > a {
    background: transparent;
  }
  ul li.expanded ul.dropdown-menu li {
    ul li {
      a {
        padding-left: $base-font-size * 2.25;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
  ul.active-menu li.expanded:not(.active) ul.active-menu {
    display: none;
  }
}

ul.mobile-active-menu > li:not(.active-trail) {
  display: none;
}

.root-menu-link a {
  font-size: $base-font-size * 0.8;
  font-weight: bold;
  &::before {
    @extend %icon-top-right;

    display: inline-block;
    margin-right: 4px;
    transform: rotate(270deg);
    vertical-align: sub;
  }
}

[id^="block-bootstrap-dcl-hoursloginmenu"] {
  grid-row: 1;
  grid-column: 3;
  justify-self: end;
  ul.menu--hours-login-menu {
    position: relative;
    > li {
      float: left;
      padding: 10px !important;
      font-size: $base-font-size;
      .navbar-text {
        margin: 0;
      }
      &.item-1 {
        &:hover,
        &:focus-visible {
          border-radius: 4px !important;
        }
        > a {
          &:hover,
          &:focus-visible {
            background-color: transparent;
          }
          &::before {
            @extend %icon-clock;

            margin-right: 4px;
            vertical-align: sub;
          }
        }
      }
      &.account {
        display: block !important;
        > span {
          &.dropdown-toggle {
            color: $dart-green !important;
            &::before {
              @extend %icon-lock-open;

              margin-right: 4px;
              vertical-align: bottom;
            }
            &::after {
              display: none;
            }
          }
          .caret {
            display: none;
          }
        }
        ul.dropdown-menu {
          right: 0 !important;
          width: 100% !important;
          min-width: 250px;
          padding: 4.5px 9px !important;
          li {
            padding: $base-font-size * 0.25 $base-font-size * 0.5 !important;
          }
          a {
            text-transform: none;
            &::before {
              margin-right: 0;
              content: "";
            }
          }
        }
      }
      a,
      span {
        text-transform: uppercase;
        color: $dart-green !important;
      }
    }
  }
}
[id^="block-globaldropdownmenu"] {
  grid-row: 2;
  justify-self: end;
}
[id^="block-primoglobalgeneralsearchform-primary"] {
  grid-row: 2;
  justify-self: end;
  max-width: 400px;
}
[id^="block-bootstrap-dcl-branding"] {
  grid-row: 1 / span 2;
  grid-column: 1;
  align-self: end;
}

@media (max-width: em(991px)) {
  header,
  .primary-secondary-menus-inner-wrapper {
    width: 100% !important;
  }
}

@media (max-width: em(767px)) {
  #universal-top-menu {
    margin-bottom: 3px;
  }
  .btn-nav-dartmouth a span.btn-txt {
    display: inline !important;
  }
  .primary-secondary-menus-inner-wrapper {
    min-height: unset !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  // remove the caret from the top of the expanded menu in mobile view
  .region-secondary-menu {
    ul {
      li.expanded.dropdown.open {
        ul.dropdown-menu::before {
          content: "";
        }
      }
    }
  }

  .dcl-sidebar-first {
    padding: 0 24px 24px 24px;
    background-color: $teal;
    .region-sidebar-first {
      text-align: left;
      h2 {
        padding-right: 0;
        padding-left: 15px;
        border-top: none;
      }
      ul li ul.menu {
        text-align: left;
        a {
          margin-left: $base-font-size * 0.6;
        }
      }
    }
  }
}

// footer copyright
.site-footer__bottom .region-footer-copyright ul {
  float: none;
  text-align: center;

  li {
    display: inline-block;
  }
}

// Code for the new global menu using dropdown to show submenus Dec 2018
.primary-secondary-menus-inner-wrapper .navbar-toggle {
  padding: 15px 0;
}

// WAVE tool fix
.region-primary-menu nav.column-menu {
  place-self: center right;
  > h2 {
    color: $white;
  }
}

.region-primary-menu nav.column-menu .dropdown:hover > .dropdown-menu,
.region-primary-menu nav.column-menu .dropdown.hover > .dropdown-menu {
  position: absolute;
  z-index: 1010;
  right: 0;
  left: unset;
  max-width: 991px;
  padding: 0;
  background-color: $forest-green;
  a,
  span {
    color: $white;
    &:hover {
      color: $white;
    }
  }
}

.region-primary-menu nav.column-menu .dropdown.account {
  display: none;
  a {
    color: $white !important;
    &:hover {
      color: $white;
      background-color: transparent;
    }
  }
  span.account {
    vertical-align: middle;
  }
  &:hover,
  &.hover {
    > a,
    > span {
      text-decoration: none;
      color: $white !important;
      background-color: transparent;
      .account::after {
        color: $white !important;
      }
    }
  }
}
.region-primary-menu nav.column-menu .dropdown.account > .dropdown-menu {
  top: 83%;
  display: block;
  width: 25%;
  padding-left: 3em;
  li {
    display: list-item;
    a {
      font-size: $base-font-size * 0.9;
    }
  }
}
.region-primary-menu nav.column-menu .dropdown.account:hover > .dropdown-menu,
.region-primary-menu nav.column-menu .dropdown.account.hover > .dropdown-menu {
  z-index: 1010;
  right: 5px;
  left: auto;
  display: block;
  width: 25%;
  min-width: 0;
  padding-left: 3em;
  li {
    display: list-item;
    list-style: none;
    text-align: left;
    color: $dart-green;
    a {
      display: inline;
      white-space: normal !important;
      color: $white !important;
      &:hover,
      &:focus-visible,
      &:active {
        margin-bottom: 0 !important;
        text-decoration: none;
        color: $white !important;
        border-bottom: 1px solid $spring-green;
      }
    }
    &:hover,
    &.hover,
    &:focus-visible {
      color: $dart-green;
    }
  }
}

.region-primary-menu nav.column-menu > ul.menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1%, max-content));
  margin: 0;
  padding-top: 0;
  column-gap: $base-font-size;
  &::after,
  &::before {
    display: none;
  }
  .icon-expand-more::after {
    vertical-align: middle;
    line-height: 0;
  }
  > li {
    position: static;
    display: table-cell;
    font-size: $base-font-size;
    line-height: normal;

    .caret {
      margin-left: 0;
      border-top: 8px dashed;
      border-top: 8px solid \9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
    > a.dropdown-toggle,
    > span.dropdown-toggle {
      float: left;
      &:hover {
        color: $white;
      }
    }
    &:hover,
    &.hover {
      cursor: pointer;
      border-radius: 4px 4px 0 0;
      background-color: $forest-green;
      .caret,
      .icon-expand-more::after {
        @extend %icon-expand-less;

        color: $white;
      }
      > a,
      > a:focus-visible,
      > a:active,
      > a:hover {
        text-decoration: none;
        color: $white !important;
        border-bottom: 1.5px solid $spring-green;
      }
      > span {
        color: $white !important;
      }
    }
  }
  li .icon-tab-toggle {
    font-size: 2rem;
  }
}

.region-primary-menu nav.column-menu .dropdown > .dropdown-menu {
  position: absolute;
  z-index: -9999;
  top: 83%;
  left: -9999em;
  display: block;
  width: 100%;
  padding: 2rem;
  color: $white;
  border: none;
  background-color: $forest-green;

  .item-description {
    margin: 1rem 1rem 1rem 0;
    font-size: $base-font-size * 0.8;
  }
  li {
    max-width: 100%;
  }

  .submenu-group {
    max-width: 100%;
    padding-top: 0;
    list-style-type: none;
    text-align: left;
    border-left: none;

    ul {
      display: block;
      margin: 1rem 0 0 0 !important;
      padding-left: 0;
      column-count: 3;
      column-gap: 2rem;

      li {
        position: relative;
        display: block;
        width: inherit;
        width: 100%;
        max-width: 100%;
        margin-bottom: 50px;
        padding: 0 !important;
        text-align: left;
        page-break-inside: avoid;
        break-inside: avoid;
        &:nth-of-type(2n) {
          margin-bottom: 0;
        }
        a {
          color: $white !important;
          border-bottom: 1.5px solidtransparent;
          font-size: $base-font-size;
          font-weight: bold;
          &:hover,
          &:focus-visible,
          &:active {
            color: $white !important;
            border-bottom: 1.5px solid $spring-green;
          }
        }
        ul.dropdown-menu,
        .dropdown-menu {
          position: relative;
          top: 0;
          left: 0;
          display: block;
          float: none;
          padding: 0 !important;
          column-count: 1;
          border: none;
          background-color: transparent;
          box-shadow: none;
          li {
            display: inline-block;
            width: 100%;
            margin: 0;
            padding: 0 !important;
            text-align: left;
            text-indent: 1rem;
            a {
              letter-spacing: normal;
              text-transform: capitalize;
              font-size: $base-font-size;
              font-weight: normal;
            }
            ul.dropdown-menu {
              margin: 0 !important;
            }
          }
        }
        span {
          display: block;
        }
      }
    }
  }

  > li.additional-links {
    max-width: 100%;
    margin-left: 0;
    padding-left: 2rem;
    text-align: left;
    font-size: 1.2em;
    .additional-links-header {
      display: flex;
      align-items: center;
      margin-bottom: 9px;
      &::after {
        flex: 1;
        height: 1px;
        margin-left: 1rem;
        content: "";
        background-color: $white;
      }
    }
    > ul {
      max-width: 100%;
      margin: 0 !important;
      padding: 0;
      list-style-type: disc;
      li {
        margin: 0 !important;
        padding: 0 !important;
        list-style: none;
        text-align: center;
        &:hover,
        &.hover,
        &:focus-visible {
          cursor: default;
        }
        span {
          display: block;
          float: none;
          text-align: left;
          font-size: $base-font-size;
        }
        a {
          display: inline;
          white-space: normal !important;
          color: $white;
          border-bottom: 1.5px solid;
          font-size: $base-font-size * 0.8;
          &:hover,
          &:focus-visible,
          &:active {
            margin-bottom: 0 !important;
            text-decoration: none;
            color: $white;
            border-bottom: 1.5px solid $spring-green;
          }
        }
        &.active a {
          background-color: transparent;
        }
      }
      > li {
        margin: 0 !important;
        padding: 0 !important;
      }
      ul.dropdown-menu,
      .dropdown-menu {
        position: static;
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        margin-left: 0 !important;
        padding: 0;
        border: none;
        background-color: transparent;
        box-shadow: none;
        &::after,
        &::before {
          display: none;
        }
        li {
          margin: 6px 0 14px 20px;
          padding: 0;
          list-style: none;
          text-align: left;
          color: $white;
          &:hover,
          &.hover,
          &:focus-visible {
            color: $white;
          }
        }
      }
    }
  }
}

.region-primary-menu.dcl-scrolled nav.table-menu > ul.nav > li.dropdown {
  padding-top: 18px !important;
  padding-bottom: 14px !important;
  > .dropdown-toggle {
    &.active-hover::before {
      bottom: -16px;
      left: -50px;
      padding: 15px 0 0 50px;
    }
    &.active-hover::after {
      right: -50px;
      bottom: -16px;
      padding: 15px 50px 0 0;
    }
  }
}

// table layout for menu items to allow vertical aligmment of
// titles. integrate with above if needed most likely we can delete
// this
.region-primary-menu
  nav.column-menu.table-menu
  .dropdown
  > .dropdown-menu
  .submenu-group {
  ul {
    display: grid;
    grid-template-rows: repeat(2, fit-content(100%));
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $base-font-size;
    width: 100%;
    max-width: 100%;
    column-count: unset;
    column-gap: unset;
    li {
      margin-bottom: 0 !important;
      padding: 0 16px 0 0 !important;
      vertical-align: top;
    }
  }
}

// mega menu
.region-primary-menu ul.nav.menu > li {
  &:hover,
  &:focus,
  &.hover {
    .mega-menu-items {
      position: absolute;
    }
  }
}
.region-primary-menu ul.nav.menu li {
  &:hover,
  &:focus-visible {
    button {
      color: $white;
    }
  }
  .dropdown-menu.fade-in-dropdown {
    transition-delay: 0s;
  }
  button {
    padding: 0;
    text-align: left;
    border: none;
    background-color: transparent;
    &:hover,
    &:focus-visible {
      color: $white;
      background-color: $forest-green;
    }
  }
  &.mega-menu-mobile-only {
    display: none;
  }
  .mega-menu-wrapper {
    position: relative;
    cursor: default;
  }
  .mega-menu-list {
    width: 100%;
    max-width: 100%;
    margin: 0 !important;
    padding: $base-font-size 0;
    list-style: none;
    color: $white;
    font-size: $base-font-size;
    a {
      margin-bottom: 0 !important;
      color: $white;
      border: none !important;
      &:hover,
      &:focus-visible {
        margin-bottom: 0 !important;
        text-decoration: underline solid $spring-green;
        text-underline-offset: 4px;
        color: $white;
        border: none !important;
      }
    }
    li.mega-menu-list-item {
      display: block;
      width: 29rem;
      height: 100%;
      padding: 0 !important;
      text-align: left;
      border-bottom: 1px solid $gray-intro;
      &.mega-menu-top-level-link {
        align-self: end;
        padding-top: $base-font-size !important;
        padding-bottom: 0;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        border-bottom: none;
        font-size: $base-font-size * 0.7;
        font-weight: bold;
        > a::after {
          @extend %icon-arrow-right;

          vertical-align: bottom;
        }
      }
      > a,
      > button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: $base-font-size * 0.75 $base-font-size !important;
        text-align: left;
      }
      > button {
        &:hover,
        &:focus-visible {
          color: $black;
          background-color: $spring-green;
        }
        &::after {
          @extend %icon-chevron-right;

          margin-left: $base-font-size * 0.5;
          vertical-align: bottom;
        }
      }
      &.item-focus,
      &:hover,
      &:focus-visible {
        > button {
          color: $black;
          background-color: $spring-green;
        }
        > a {
          text-decoration: underline solid $spring-green;
          text-underline-offset: 4px;
          color: $white;
          border-bottom: 1px solid transparent;
        }
      }
      .mega-menu-items {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 29rem);
        height: 100%;
        padding: $base-font-size $base-font-size * 1.5;
        text-align: left;
        border-left: 0.5px solid $gray-intro;
        .mega-menu-section-header {
          margin-bottom: $base-font-size;
          .section-title {
            font-size: $base-font-size * 1.75;
            font-weight: 600;
          }
          .section-desc {
            font-weight: 100;
          }
        }
        ul.mega-menu-section-list {
          display: block;
          max-width: 100%;
          padding: 9px 0 0 0;
          columns: 2;
          gap: $base-font-size;
          border-top: 1px solid $gray-intro;
          li.mega-menu-section-item {
            display: inline-block;
            width: 100%;
            list-style: none;
            text-align: left;
            a {
              &:hover,
              &:focus-visible {
                text-decoration: underline solid $spring-green;
                text-underline-offset: 4px;
              }
            }
            &.view-all-link {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.region-primary-menu > nav.table-menu.column-menu {
  > ul.nav > li.dropdown.account.hover > .dropdown-menu {
    display: block !important;
  }
}
.icon-close-times-remove,
.icon-menu {
  font-size: $base-font-size * 1.5;
  line-height: 0;
}

.fade-in-dropdown {
  transition-delay: 300ms;
  animation-name: fade-in-opacity;
  animation-duration: 600ms;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  opacity: 1;
}

@keyframes fade-in-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.region-primary-menu
  nav.column-menu
  .dropdown
  > .dropdown-menu
  > li.additional-links
  > ul
  li
  span {
  display: block;
  float: none;
  text-align: left;
}

.region-primary-menu
  nav.column-menu.grid-menu
  .dropdown
  > .dropdown-menu
  .submenu-group {
  ul {
    @include grid-rows($dcl-nav-menu-rows);

    display: grid;
    -ms-grid-columns: minmax(33%, 33%) minmax(33%, 33%) minmax(33%, 33%);
    grid-template-columns: repeat(3, minmax(33%, 33%));
    grid-auto-flow: column;
    column-count: unset;
    column-gap: unset;

    li {
      // IE specific placement
      @include ie-grid-placement($dcl-nav-menu-rows, $dcl-nav-menu-columns);

      width: 100% !important;
      margin-bottom: 0 !important;
      padding: 0 16px 0 0 !important;
      vertical-align: top;

      &:nth-of-type(2n) {
        margin-bottom: inherit;
      }
    }
  }
}

// body disable for mobile nav
html.mobile-nav-open,
body.mobile-nav-open {
  .dcl-fixed-header {
    overflow: intiial;
    .region-mobile-menu {
      overflow: hidden scroll;
    }
  }
}

@media (max-width: em(991px)) {
  .navbar-toggle {
    display: block;
  }
  .primary-secondary-menus {
    float: left;
    .navbar-header {
      float: none;
      align-self: center;
      button.navbar-toggle:focus-visible {
        outline: $off-white solid 3px;
        outline-offset: 2px;
      }
    }

    #primary-secondary-menus-wrapper {
      display: grid;
      grid-template-columns: 1fr fit-content(100%);
      gap: 36px;
      min-height: 72px;
    }
    .region-primary-menu {
      grid-template-columns: fit-content(100%) fit-content(100%);
      .nav,
      .column-menu,
      nav.column-menu > ul.menu {
        display: none;
      }
      .search--dcl-unified-search-form {
        justify-self: right;
        width: 50%;
        max-width: 100%;
      }
    }
    .navbar-toggle {
      margin: 0;
      padding: 9px;
      &[aria-expanded="true"] {
        color: $white;
        background-color: $forest-green;
      }
    }
    .icon-close-times-remove {
      display: none;
    }
    #global-mobile-menu {
      .search--dcl-unified-search-form {
        display: none;
      }
      .region-mobile-menu nav ul.menu > li:first-of-type {
        border: none;
      }
    }
  }

  @media (max-width: em(767px)) {
    .page-node-type-hub-page,
    .page-node-type-page {
      .hero-menu-wrapper {
        display: block;
      }
    }
    .primary-secondary-menus {
      #primary-secondary-menus-wrapper {
        grid-template-columns: fit-content(100%) 1fr;
        .search--dcl-unified-search-form {
          display: none;
        }
      }
      #global-mobile-menu {
        .search--dcl-unified-search-form {
          display: block;
        }
      }
    }
  }

  // IE and edge have scroll bars, so move the close alignment
  @mixin ie-scrollbar-spacer {
    .primary-secondary-menus .icon-close-times-remove {
      margin-right: 16px;
    }
  }

  // IE and edge have scroll bars, so move the close alignment
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include ie-scrollbar-spacer;
  }

  @supports (-ms-accelerator: true) {
    @include ie-scrollbar-spacer;
  }

  @supports (-ms-ime-align: auto) {
    @include ie-scrollbar-spacer;
  }
}

// stylelint-disable-next-line order/order
#global-mobile-menu {
  position: absolute;
  z-index: 10;
  top: 50px;
  right: 0;
  width: 100%;
  max-width: 600px;
  .region-mobile-menu {
    color: $white;
    background-color: $forest-green;
    .search--dcl-unified-search-form {
      float: none;
      padding: $base-font-size * 0.5 0;
      border-bottom: 1px solid $college-background-gray;
      .input-group {
        width: 100%;
        padding: 10px 0 10px $base-font-size;
      }
    }
    [id^="block-globaldropdownmenu"] {
      width: 100%;
    }
    .dropdown-menu > .active > a {
      background-color: transparent !important;
    }

    nav ul.menu {
      max-width: 100%;
      > li {
        margin: 0;
        border-collapse: collapse;
        border-top: 1px solid $college-background-gray;
        &.submenu-group {
          border-bottom: none;
        }
        button {
          padding: 0;
          border: none;
          background-color: transparent;
        }
        > a,
        > button {
          display: inline-block;
          width: 100%;
          padding: $base-font-size * 0.5 $base-font-size !important;
          text-align: left;
          color: $white;
          &:hover,
          &:focus-visible {
            text-decoration: none;
            outline-style: none;
            background-color: $forest-green;
          }
          &.dropdown-toggle {
            &:hover,
            &:focus-visible {
              background-color: $forest-green;
            }
          }
        }
        button,
        button.dropdown-toggle {
          &:hover,
          &:focus-visible {
            color: $black;
            background-color: $spring-green;
          }
        }
        a {
          &:hover,
          &:focus-visible {
            text-decoration: underline solid $spring-green;
            text-underline-offset: 4px;
          }
        }
        > :last-child {
          padding-bottom: $base-font-size * 0.5;
        }
        > button::after {
          @extend %icon-chevron-right;

          margin-left: 4px;
          vertical-align: sub;
        }
      }
      &.mega-menu-mobile-only {
        display: block;
      }
      .mega-mobile-selected,
      .mega-mobile-selected.dropdown-toggle {
        color: $black;
        background-color: $spring-green;
        &:hover,
        &:focus-visible {
          color: $black;
          background-color: $spring-green;
        }
      }
      .mega-menu-section-header {
        display: none;
      }
      .mega-menu-list {
        padding: 0;
        .mega-menu-list-item {
          margin: 0;
          &.mega-menu-top-level-link {
            letter-spacing: 1.5px;
            text-transform: uppercase;
            font-size: $base-font-size * 0.7;
            font-weight: bold;
            > a::after {
              @extend %icon-arrow-right;

              vertical-align: bottom;
            }
          }
          a,
          button {
            display: inline-block;
            padding: $base-font-size * 0.5 $base-font-size $base-font-size * 0.5
              $base-font-size * 2.2;
            text-align: left;
            color: $white;
            &.mega-mobile-selected {
              padding-left: $base-font-size;
              color: $black;
            }
          }
          button {
            &:hover,
            &:focus-visible {
              text-decoration: none;
              color: $black;
              background-color: $spring-green;
            }
            &::after {
              @extend %icon-chevron-right;

              margin-left: 4px;
              vertical-align: sub;
            }
          }
        }
        .mega-menu-section-list {
          padding: 0;
          .mega-menu-section-item {
            margin: 0;
          }
        }
      }
    }
    nav ul li {
      position: relative;
      display: block;
      clear: both;
      width: 100%;
      margin: 0 0 8px 0;
      a,
      button {
        display: inline-block;
        width: 100%;
        padding: $base-font-size * 0.5 $base-font-size;
      }
      a {
        &:hover,
        &:focus-visible {
          text-decoration: underline;
        }
      }
      a:focus-visible {
        outline: solid;
      }
      span.icon-tab-toggle {
        display: none;
      }
      &.additional-links {
        display: block;
        max-width: 100%;
      }
      .mobile-open-show {
        float: left;
        margin: 12px 0 8px 0;
        padding: 0;
        color: $white;
        font-size: 16px;
        &.mobile-nav-open {
          transform: rotate(180deg);
        }
        &:hover {
          cursor: pointer;
        }
      }
      .dropdown-menu {
        width: 100%;
        padding: 0;
        color: $white;
        border: none;
        border-radius: 0;
        background-color: $forest-green;
        -webkit-box-shadow: none;
        box-shadow: none;
        &.mobile-nav-open {
          position: static;
          display: block !important;
        }
        li {
          width: 100%;
          border-bottom: solid 1px $white;
          a {
            width: auto;
            padding: 10px 15px;
            color: $white !important;
            &:hover {
              color: $white !important;
            }
          }
        }
      }
      &.item-focus {
        > button {
          &::before {
            @extend %icon-chevron-left;

            margin-right: 4px;
            vertical-align: sub;
          }
          &::after {
            display: none;
          }
        }
      }
    }
    .region-primary-menu ul.nav.menu li {
      .mega-menu-items {
        position: relative;
      }
    }
    .caret {
      border-top: 8px dashed;
      border-top: 8px solid \9;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      &.mobile-nav-open {
        transform: rotate(180deg);
      }
    }
  }
}

// project page overrides
.dcl-project .dcl-fixed-header,
.dcl-dcl-project .dcl-fixed-header {
  .search--dcl-unified-search-form,
  .region-primary-menu .menu--global-dropdown-menu,
  .icon-close-times-remove,
  [id*="block-bootstrap-dcl-hoursloginmenu"] {
    display: none;
  }
  .region-primary-menu {
    float: left;
    width: auto;
  }
  .primary-secondary-menus {
    #primary-secondary-menus-wrapper {
      display: grid;
      grid-template-columns: 1fr fit-content(100%);
      gap: $base-font-size * 2;
      min-height: $base-font-size * 4;
    }
    .navbar-header {
      align-self: center;
    }
  }
  .primary-secondary-menus-inner-wrapper {
    min-height: unset !important;
    padding: 5px 15px;
  }
  .navbar-toggle {
    position: relative;
    display: block;
    float: right;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    padding: 9px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    background-image: none;
    &[aria-expanded="true"] {
      color: $white;
      background-color: $forest-green;
    }
  }
  #global-mobile-menu {
    top: 70px;
    max-width: 600px;
    .search--dcl-unified-search-form {
      display: block;
      max-width: 100%;
    }
  }
}

@media (max-width: 990px) {
  .dcl-project .dcl-fixed-header,
  .dcl-dcl-project .dcl-fixed-header {
    #global-mobile-menu {
      top: 50px;
    }
  }
}
