#content {
  @extend .col-sm-9;
  @extend .pull-right;
}
header {
  @extend .container;
}

.header-site-name {
  @extend .navbar-header;
  @extend .col-md-12;
  @extend .col-sm-12;
}

.region-universal-top {
  @extend .col-md-12;
  @extend .col-sm-12;
}
.copyright {
  @extend .col-sm-12;
  @extend .center-block;
}
.dept-info-mobile {
  @extend .visible-xs-block;
}

#department-banner aside {
  @include clearfix;
}
.featured-top aside {
  @include clearfix;
}

#block-unifiedsearchform {
  @extend .col-md-4;
  @extend .col-sm-6;
  @extend .pull-right;
}

.primary-secondary-menus {
  @extend .container;
}
.primary-secondary-menus-inner-wrapper {
  @extend .container;
}

.library-utility-bar {
  @extend .nav;
}
#main {
  @extend .row;
}
#mobile-menu {
  @extend .visible-xs-block;
}
.page-branding {
  @extend .col-sm-4;
}

.page-primary-menu {
  @extend .pull-right;
}

#block-bootstrap-dcl-utilitymenu ul {
  @extend .navbar-right;
}

#page-wrapper {
  @extend .container-fluid;
}

#search-form {
  .search-submit {
    @extend .btn;
    @extend .btn-default;
  }
}

#sidebar {
  @extend .col-sm-3;
  @extend .pull-left;
}

footer {
  @extend .container;

  @include clearfix;

  .region-footer-fourth {
    @extend .col-sm-2;
  }

  .region-footer-copyright {
    @extend .row;
    @extend .col-sm-12;
  }
}

.path-frontpage {
  .home-feature-primary,
  .home-feature-secondary {
    @extend .row;
  }
  .region-home-feature-primary-first,
  .region-home-feature-primary-second,
  .region-home-feature-primary-third,
  .region-home-feature-secondary-first {
    @extend .col-sm-4;
  }

  .region-home-feature-secondary-second {
    @extend .col-sm-8;
  }
}

.home-feature-news-blogs {
  @extend .row;
  .views-row {
    @extend .col-sm-6;
    &:nth-child(2n + 1) {
      clear: left;
    }
  }
}

.home-feature-popular-services {
  ul {
    @extend .row;

    padding-left: 30px;
    li {
      @extend .col-xs-6;
      &:nth-child(2n + 1) {
        clear: left;
      }
    }
  }
}

.paragraph--type--promo-links,
.paragraph--type--promo-links-desc {
  @extend .col-sm-6;
  @extend .col-xs-12;
}

.paragraph--type--promo,
.paragraph--type--content {
  @extend .col-sm-12;
}
