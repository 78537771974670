// @file _maintenance.scss

@media (max-width: em(767px)) {
  body.maintenance-page {
    .header-site-name {
      display: block;
      float: left;
      clear: both;
      margin-right: 0;
      margin-left: 0;
    }
    #block-bootstrap-dcl-branding {
      font-size: 2em;
    }
  }
}
