// @file
// Style for using department menus

// styles
.part-of-dept-or-project {
  display: none;
  align-content: center;
  color: $white;
  background-color: $forest-green;
  p {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-bottom: 0;
    &::after {
      flex: 1;
      height: 1px;
      margin-left: 1rem;
      content: "";
      background-color: $spring-green;
    }
    button.dcl-dept-small-screen-toggle-disabled,
    button.dcl-dept-small-screen-toggle {
      border: none;
      background-color: transparent;
      &:hover,
      &:focus-visible {
        text-decoration: none;
        border: none;
        &::after {
          text-decoration: none;
          border: none;
        }
      }
      &::after {
        display: none;
      }
    }
  }
}
.page-node-type-page,
.page-node-type-hub-page {
  button.dcl-dept-small-screen-toggle {
    display: none;
  }
}
.dept-or-collection-menu-wrapper {
  z-index: 2;
  margin-top: 9px;
  margin-left: $base-font-size;
  &.no-dept-or-collection-menu-header {
    margin-left: 0;
  }
  ul.dept-nav {
    display: flex;
    flex-wrap: wrap;
    gap: $base-font-size * 0.5 $base-font-size * 2;
    max-width: 100%;
    padding: 0;
    &::after,
    &::before {
      display: none;
    }
    span {
      padding: 0 9px;
      color: $gray-medium;
    }
    li {
      padding: 0;
      list-style: none;
      a {
        display: inline;
        padding: 0;
        text-decoration: none;
        color: $dart-green;
        border-bottom: 1.5px solid $dart-green;
        &.active-dept-collection-link {
          border-bottom: 4.5px solid $spring-green;
        }
        &:hover {
          color: $dart-green;
          border-bottom: 1.5px solid $spring-green;
          outline: transparent;
          background-color: transparent;
        }
        &:focus-visible {
          background-color: transparent;
        }
      }
    }
  }
  #hours-dropdown-menu {
    padding: 0;
    border: none;
    background: none;
    span {
      color: $black;
      &.icon-clock {
        margin-right: 4px;
        padding: 0;
      }
      &.icon-tab-toggle {
        padding: 0;
      }
    }
  }
  #hours-dropdown-list {
    padding: $base-font-size;
    li {
      margin-top: $base-font-size * 0.5;
      padding-top: $base-font-size * 0.5;
      border-top: 1px solid $gray-intro;
      span {
        display: block;
        padding: 0;
        color: $black;
        font-weight: bold;
      }
      &:first-of-type {
        margin: 0;
        padding-top: 0;
        border: none;
      }
    }
  }
}

@media (max-width: em(767px)) {
  .page-node-type-hub-page,
  .page-node-type-page {
    .hero-menu-wrapper {
      padding-left: 5px;
    }
    .dcl-sidebar-first {
      display: none;
    }
  }
  .part-of-dept-or-project {
    display: block;
    p {
      display: grid;
      grid-template-columns: 1fr;
      padding: 3px;
      color: $white;
      border-radius: 4px;
      background-color: $forest-green;
      font-size: $base-font-size;
      &::after {
        height: unset;
        margin-left: 0;
        background-color: transparent;
      }
      &:hover {
        cursor: pointer;
        button.dcl-dept-small-screen-toggle {
          color: $white;
          border-radius: 4px 4px 0 0;
          background-color: $forest-green;
        }
      }
      &.open {
        border-radius: 4px 4px 0 0;
      }
      button.dcl-dept-small-screen-toggle {
        display: grid;
        grid-template-columns: 1fr fit-content(100%);
        justify-items: start;
        width: 100%;
        padding: $base-font-size * 1.4 $base-font-size * 0.5;
        color: $white;
        border: none;
        line-height: 0;
        &.open,
        &:hover {
          color: $white;
          border-radius: 4px 4px 0 0;
          background-color: $forest-green;
        }
        &::after {
          display: inline-block;
          float: right;
          color: $spring-green;
          font-size: $base-font-size * 1.75;
          font-weight: 300;
        }
      }
    }
  }
  .dept-or-collection-menu-wrapper {
    position: relative;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
    border-radius: 0 0 4px 4px;
    background-color: $college-form-gray;
    font-size: $base-font-size;
    ul.menu {
      position: absolute;
      display: none;
      width: 100%;
      background-color: $college-form-gray;
      li {
        border-bottom: 1px solid;
        span {
          display: none;
          &.quick-links-menu {
            display: inline-block;
            padding: 9px 18px;
            color: $black;
          }
        }
        #hours-dropdown-menu {
          display: block;
          padding: 9px 18px;
          color: $black;
          span.icon-tab-toggle,
          span.icon-clock {
            display: inline;
            color: $black;
          }
          span.icon-clock {
            margin-right: 4px;
            padding: 0;
          }
        }
        ul {
          padding-left: 0;
          list-style: none;
          font-size: $base-font-size * 0.85;
          li {
            padding-left: 0;
            border-top: 1px solid;
            border-bottom: none;
            background-color: $college-background-gray;
            a {
              padding-left: 36px;
            }
          }
        }
        ul#hours-dropdown-list {
          position: relative;
          width: 100%;
          margin-top: 0;
          padding: 0;
          background-color: $college-background-gray;
          box-shadow: none;
          li {
            margin: 0;
            padding: 0;
            span {
              color: $black;
              background-color: transparent;
            }
            p {
              margin: 0;
              padding: $base-font-size * 0.5 $base-font-size * 2;
            }
            a {
              padding: $base-font-size * 0.5 $base-font-size * 2;
              text-decoration: underline solid;
              color: $black;
              &:hover,
              &:focus-visible {
                color: $white;
              }
            }
          }
        }
        a,
        button {
          padding: 0 9px;
          color: $white;
        }
        a {
          display: inline-block;
          width: 100%;
          padding: 9px 18px;
          color: $black;
          border-bottom: none;
          &.active-dept-collection-link {
            color: $black;
            border: none;
            background-color: $spring-green;
          }
          &:hover,
          &:focus-visible {
            color: $white;
            border-bottom: none;
            background-color: $dart-green;
          }
        }
        &.active {
          background-color: $spring-green;
          a {
            color: $black;
            &:hover,
            &:focus-visible {
              color: $white;
            }
            &.is-active {
              color: $white;
              background-color: $forest-green;
            }
          }
        }
        &.contact-info-staff,
        &.contact-info-link {
          a {
            &::before {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.region-sidebar-first {
  .dcl-hours-list {
    padding: 10px 0;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: $dart-green;
    font-size: $base-font-size * 0.75;
    font-weight: bold;
    .icon-clock {
      margin-right: 4px;
      &::after {
        vertical-align: bottom;
      }
    }
  }
  .dcl-hours-list-grid {
    display: grid;
    grid-template-columns: 1fr fit-content(100%);
    gap: $base-font-size * 1.5;
    margin: $base-font-size * 0.5 0 $base-font-size * 0.5 $base-font-size * 1.5;
    .dcl-hours-list-key {
      letter-spacing: normal;
      text-transform: none;
      font-weight: normal;
    }
    .dcl-hours-list-value {
      font-weight: bold;
    }
  }
  #dcl-hours-list-all a::after {
    @extend %icon-arrow-right;

    vertical-align: bottom;
    color: $dart-green;
  }
}

@media (max-width: em(767px)) {
  .dept-or-collection-menu-wrapper {
    .dcl-hours-list {
      margin-left: $base-font-size * 2;
      color: $white;
      ul {
        padding-left: 0;
        li.dcl-hours-list-grid {
          padding-left: $base-font-size * 1.67;
          span {
            display: inline;
            letter-spacing: 0.5px;
            color: $white;
            &.dcl-hours-list-value {
              font-weight: bold;
            }
          }
        }
        #dcl-hours-list-all {
          padding: 0;
          a {
            padding-left: $base-font-size * 1.2;
          }
        }
      }
    }
  }
}
