// @file _node.scss
// Node level element styles, such as page title.

// originally from classy/node
.node--unpublished {
  background-color: $cream;
}

.page-header {
  margin: 5px;
}
h1,
.h1 {
  &.page-header {
    display: none;
  }
}

.field--name-field-summary,
.field--name-field-intro {
  margin-bottom: 2.8rem;
  font-family: $font-family-serif-base;
  font-size: $base-font-size * 1.3;
  line-height: 125%;
  b,
  strong {
    font-weight: 400;
  }
  .font-family-serif-loaded & {
    font-family: $font-family-serif;
  }
}
body:not(.path-frontpage).field--name-body {
  margin-bottom: $component-spacing-bottom;
}

// 404 page
.page-not-found-error {
  .region-dcl-notices {
    display: none;
  }
  .region-content > article {
    display: grid;
    grid-template-columns: 225px 1fr;
    gap: $base-font-size;
    margin-top: $base-font-size * 0.5;
    &::before {
      height: 225px;
      content: "";
      background-image: url("#{$images_path}/404.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
    h1 {
      height: 225px;
      background-image: url("#{$images_path}/404.png");
      background-repeat: no-repeat;
      background-size: contain;
      span {
        display: none;
      }
    }
    .field--name-field-intro {
      margin-top: 0;
    }
  }
}

@media (max-width: em(767px)) {
  .page-not-found-error {
    .region-content {
      display: block;
      .field--name-field-intro {
        margin-top: 18px;
      }
    }
  }
}
