// Buttons snippet from bootstrap to prevent bloat by extending nested selectors
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.dart-btn {
  @include button-size(
    $padding-base-vertical,
    $padding-base-horizontal,
    $font-size-base,
    $line-height-base,
    $btn-border-radius-base
  );
  @include user-select(none);

  display: inline-block;
  margin-bottom: 0; // For input.btn
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  font-weight: bold;
  touch-action: manipulation;

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
    color: $btn-default-color;
  }

  &:active,
  &.active {
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));

    outline: 0;
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @include opacity(0.65);
    @include box-shadow(none);

    cursor: $cursor-disabled;
  }
}

// Baseline table styles

.dart-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $line-height-computed;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: 1px solid $table-border-color;
        line-height: $line-height-base;
      }
    }
  }

  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $table-border-color;
  }

  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }

  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  // Nesting
  .table {
    background-color: $body-bg;
  }
}

// Zebra-striping
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.dart-table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: $table-bg-accent;
  }
}

// custom mixins based on bootstrap core

@mixin dart-hover() {
  &:hover {
    color: $white !important;
    background-color: $forest-green !important;
  }
}

@mixin dart-button() {
  @extend .dart-btn;

  @include button-variant($white, $dart-green, $btn-default-border);
  @include dart-hover;
}

@mixin button-default() {
  @extend .dart-btn;

  @include button-variant(
    $btn-default-color,
    $btn-default-bg,
    $btn-default-border
  );
  @include dart-hover;
}

@mixin button-highlight() {
  @extend .dart-btn;

  @include button-variant($btn-default-color, $yellow, $btn-default-border);
  @include dart-hover;
}

@mixin button-sm() {
  @extend .dart-btn;

  @include button-variant(
    $btn-default-color,
    $btn-default-bg,
    $btn-default-border
  );
  @include button-size(
    $padding-small-vertical,
    $padding-small-horizontal,
    $font-size-small,
    $line-height-small,
    $btn-border-radius-small
  );
  @include dart-hover;
}

@mixin button-xs() {
  @extend .dart-btn;

  @include button-variant(
    $btn-default-color,
    $btn-default-bg,
    $btn-default-border
  );
  @include button-size(
    $padding-xs-vertical,
    $padding-xs-horizontal,
    $font-size-small,
    $line-height-small,
    $btn-border-radius-small
  );
  @include dart-hover;
}
