#back-to-top-btn {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  color: $black;
  border: 1px solid $white;
  border-radius: 0.5rem;
  background-color: $spring-green;
  &:hover,
  &:focus-visible {
    color: $white;
    border-color: $spring-green;
    background-color: $forest-green;
  }
  &:focus-visible {
    display: inline-block !important;
    outline: 3px solid $bsa-outline-color;
  }
  svg {
    width: 2.5rem;
  }
}
