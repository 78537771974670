// @file
// Style for forms.

// Contact form - feedback

// bootstrap override
.form-control,
form textarea,
form input {
  background-color: transparent;
}
form input[type="radio"] {
  background-color: transparent;
}
.alert {
  margin-left: 22px;
}

form.contact-form {
  width: 60%;
  padding: 20px 10px;
  font-family: $font-family-serif;
  label,
  legend {
    font-weight: normal;
    &.option {
      float: left;
      margin-right: 20px;
      font-size: 1em;
    }
  }
  .form-control {
    font-size: inherit;
  }
  fieldset {
    clear: both;
  }

  legend {
    margin-bottom: 0;
    border: 0 0 5px;
    border-bottom: none;
    font-size: 1em;
    font-weight: $font-weight-strong;
  }
  .form-control {
    font-size: inherit;
  }

  .field--name-field-referrer-url {
    display: none;
  }
}

.form-required::after {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 0.3em;
  content: "";
  vertical-align: super;
  background-image: url("../../../../../core/misc/icons/ee0000/required.svg");
  background-repeat: no-repeat;
  background-size: 6px 6px;
}

button[type="submit"] {
  padding: 9px 18px;
  color: $white;
  border-radius: 27px;
  background-color: $dart-green;
  font-size: $base-font-size;
  font-weight: bold;
  &:hover {
    color: $white;
    background-color: $forest-green;
  }
}

label {
  font-size: $base-font-size * 0.9;
  font-weight: normal;
}

@media (min-width: em(768px)) and (max-width: em(991px)) {
  form.contact-form {
    width: 60%;
    padding: 0 18px;
  }
  .alert {
    margin-left: 15px;
  }
}

@media (max-width: em(767px)) {
  form.contact-form {
    width: 80%;
  }
}
