// @file
// Style for search boxes.

.library-utility-search {
  margin-top: 4px;
  .search-submit::before {
    @extend %icon-search;

    margin-right: 5px;
    font-size: 90%;
  }
}

// Jones media search box

#block-bootstrap-dcl-jonesmediasearch {
  form {
    display: table;
    width: 100%;
  }

  div {
    display: table-header-group;
    width: 100%;
  }

  .options {
    display: inline;
    float: left;
  }
  input[name="searcharg"] {
    width: 50%;
    padding: 10px;
    border: 1px solid $off-white;
  }
  input[type="submit"] {
    margin: 0 0 0 10px;
    padding: 10px;
    text-transform: lowercase;
    color: $white;
    border: none;
    background-color: $dart-green;
  }
  input[type="submit"]:hover,
  input[type="submit"]:focus-visible {
    background-color: $dart-green;
  }

  input[id="keyword"]::before {
    clear: left;
    content: "";
  }

  input[type="radio"] {
    position: absolute;
    left: -100000px;
  }

  input[type="radio"] + label {
    display: inline-block;
    margin-right: 0.88889rem;
    margin-bottom: 0;
    margin-left: 0.44444rem;
    vertical-align: baseline;
  }
}

// Above here is old and can likely be removed

// search box in the banner

header {
  .search--dcl-unified-search-form {
    float: right !important;
    width: 350px;
    margin-top: -35px;
    .form-group {
      margin-bottom: 0;
    }
  }
}

.search__search-form {
  .search--dcl-unified-search-form {
    width: 350px;
    margin: 0 auto;
  }
}
.search--dcl-unified-search-form {
  input {
    border-radius: 4px !important;
    background-color: $college-form-gray;
  }

  .search-submit {
    z-index: auto;
    float: right;
    margin-left: $base-font-size !important;
    padding: 6px 16px !important;
    &:hover,
    &:focus-visible {
      background-color: $dart-green;
    }
  }
  .search-submit::before {
    @extend %icon-search;

    vertical-align: middle;
    color: $white;
  }

  .icon-search {
    @extend .offscreen;
  }
}

.region-mobile-menu {
  .search--dcl-unified-search-form {
    input {
      height: 40px;
    }
    .search-submit {
      padding: 10px 15px !important;
      background-color: $forest-green;
      &:hover {
        background-color: $dart-green;
      }
    }
  }
}

.search-form.search-page-form {
  .btn {
    background-color: $dart-green;
  }
}

// unified-search styling

.search__search-form {
  margin-bottom: 25px;
  padding: 0 5px;
}

.search_more-options-link {
  text-align: center;
  p {
    margin-bottom: 0;
  }
}

.search__counts .block-dcl-unified-search.clearfix::after {
  clear: none !important;
}

.best-bet {
  margin: 5px 0;
  padding: 1px 20px 3px 20px;
  border-radius: 4px;
  background-color: $off-white; // FIXME: use variable for ,light grey
}

.search__search-counts-wrapper {
  z-index: 2;
}

.search__counts {
  z-index: 2;
  margin-bottom: 10px;
  > div {
    margin: 4px 0;
  }
  .btn {
    margin: 5px;
  }
  .badge {
    margin-left: 5px;
    background-color: $gray-intro;
  }
}
.result-set-link-count {
  margin-top: 0;
  text-transform: none;
}

.result-set-link-count::after {
  @extend %icon-chevron-right;

  margin-left: 3px;
}

// general results

.search__results-wrapper {
  z-index: 2;
  display: table;
  padding: 0 !important;
}

.search__results-column-left {
  padding-left: 5px !important;
}
.search__results-column-right {
  padding-right: 5px !important;
}

.bento-search,
.libapp-widget,
.search__other-searches {
  z-index: 2;
  padding: 0 15px 15px 0;
}

.search__other-searches {
  padding: 0 35px 0 20px;

  .search__more-places-column-1 {
    padding-left: 0;
  }
  .search__more-places-column-2 {
    padding-right: 0;
  }
}
.bento-search,
.libapp-widget,
.search__other-searches {
  h2,
  h3 {
    padding: 0 0 5px 0;
    border-bottom: 1.5px solid $gray;
    font-size: em(18px);
    font-weight: normal;
  }
  ul {
    li {
      &::before {
        content: "";
      }
    }
  }
}
.search__other-searches {
  h2,
  h3 {
    font-size: em(18px);
    font-weight: normal;
    line-height: 100%;
  }
}

.result-set-header-icon {
  margin-right: 5px;
  color: $gray;
}

.result-set-link-count {
  @extend .pull-right;

  text-transform: none;
  font-size: 12px;
}

.result {
  margin: 5px 0 10px 0;
  padding-bottom: 10px;
  border-bottom: 1.5px solid $off-white;
  p {
    margin-bottom: 0;
  }
  .catalog-loc-avail a {
    font-size: 100%;
  }
  a.researchguide-owner {
    font-size: 100%;
  }

  p.result-bold-title {
    font-weight: 600;
  }

  p.main-entry-availability {
    display: table;
    float: left;
    a {
      margin-right: 5px;
    }
  }

  div.result-table {
    display: table;
  }

  div.tr {
    display: table-row;
  }

  div.td {
    display: table-cell;
  }

  div.td:nth-of-type(1) {
    vertical-align: top;
  }

  div.td:nth-of-type(2) {
    width: 100%;
  }

  .checked-out {
    font-weight: bold;
  }
  .more-toggle {
    @extend %dcl-a;

    margin: 0 0 10px 10px;
    cursor: pointer;
    font-size: em(12px);
  }

  .more-toggle:hover {
    @extend %dcl-a, :hover;
  }

  .result-copies {
    clear: right;
  }
}
.result:last-of-type {
  border-bottom: none;
}
.catalog-available {
  color: $dart-green;
}
.checked-out,
.item-status-available {
  display: inline-block;
  font-weight: bold;
}
.result-cover-image {
  max-width: 65px;
  margin: 5px 10px 5px 0;
}
.publication-dates {
  font-size: 80%;
}
.availability-location {
  display: table-cell;
  .search-spinner.fa.normal-right-spinner {
    margin: 0;
    font-size: 125%;
  }
  a {
    font-size: 100% !important;
  }
}
.items-availability {
  display: table;
  margin-left: 0;
  .more-toggle {
    margin-top: 3px;
    margin-left: 5px;
  }
  .item-status {
    display: block;
    padding: 0 5px;
    border-radius: 2px;
    a {
      margin-right: 5px;
    }
  }
  .item-status:nth-of-type(2n) {
    background-color: #efefef;
  }
}
.search__other-searches p.more-places-link {
  margin: 0;
  padding: 0;
}
.catalog-detail::after {
  content: " ";
}
.catalog-detail:last-of-type {
  display: block;
}

// libapps
.libanswers-topic {
  margin: 3px;
  padding: 2px 5px;
  border-radius: 2px;
  background-color: $off-white;
}
.libapp-widget {
  ul {
    padding-left: 0;
    list-style: none;
  }
  ul li {
    margin: 5px 0 10px 0;
    padding-bottom: 10px;
    border-bottom: 1.5px solid $off-white;
  }
  ul li:last-of-type {
    border-bottom: none;
  }
  .s-lg-widget-list-more-results {
    float: right;
  }
  .s-lg-widget-list-info i {
    font-style: normal !important;
  }

  .s-lg-widget-list-description {
    .more-toggle.researchguides-databases {
      margin-left: 4px;
      .more-link-styles {
        margin-left: 4px;
        cursor: pointer;
        color: $dart-green;
        border-bottom: 1.5px solid $dart-green;
      }
    }
  }
}
.s-lg-az-result-badge-popular {
  background-color: $dart-green !important;
}

// count links
a.count-link {
  margin: 5px -5px;
  border: none !important;
}
.count-link-name {
  margin-left: 15px;
}
.count-link .count-link-name {
  margin-left: 0;
  border-bottom: 1.5px solid $dart-green !important;
}

.count-link .count-link-name:hover {
  border-bottom: 1.5px solid $blue !important;
}
.count-link-icon {
  margin-left: 5px;
  color: $dart-green;
}

.more-options-help {
  margin-top: 10px;
}

#more-options-for-searching,
#more-options-for-searching:hover {
  border: none !important;
  background: none !important;
}

// the material types
.media-type {
  margin-right: 5px;
}

// best bets
.best-bet {
  -webkit-transition: background-color 1500ms linear;
  -ms-transition: background-color 1500 linear;
  transition: background-color 1500ms linear;
  font-size: 110%;
  > div {
    margin-top: 10px;
  }
  > div:nth-of-type(1) {
    margin-top: 0;
  }
  h3 {
    margin-top: 10px;
  }
}

// animations
.fa.normal-right-spinner {
  margin: 20px auto;
  animation: glyphicon-spin-r 2s infinite linear;
  font-size: 300%;
}

@keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

// Google CSE Overrides
.dart-google-search {
  margin-left: 4px;
}

.gs-result .gs-spelling {
  display: none;
}

.gsc-control-cse.gsc-control-cse-en {
  padding: 0 20px;
}

.cse .gsc-webResult.gsc-result:hover,
.gsc-webResult.gsc-result:hover,
.gsc-webResult.gsc-result.gsc-promotion:hover,
.gsc-results .gsc-imageResult-classic:hover,
.gsc-results .gsc-imageResult-column:hover {
  border: 1px solid $white !important;
}

.gs-result .gs-title,
.gs-result .gs-title * {
  height: auto !important;
  text-decoration: none !important;
}

.gs-webResult.gs-result a.gs-title {
  border-bottom: 1.5px solid $dart-green;
  &:link,
  &:visited {
    color: $dart-green !important;
  }
  &:hover {
    color: $dart-green !important;
    border-bottom: 1.5px solid $spring-green;
  }
}

.gs-result img.gs-image,
.gs-result img.gs-promotion-image {
  border: none !important;
}

.gsc-results .gsc-cursor-box .gsc-cursor-current-page {
  color: $white !important;
}

.gs-snippet {
  font-size: 16px !important;
  line-height: 25.6px !important;
}

.gcsc-branding-clickable {
  border-bottom: none !important;
}

.gcsc-branding-img-noclear {
  width: 51px !important;
}

.bento-search {
  .cse .gsc-control-cse,
  .gsc-control-cse,
  .cse .gsc-webResult.gsc-result,
  .gsc-webResult.gsc-result,
  .gsc-imageResult-classic,
  .gsc-imageResult-column {
    padding: 0 !important;
  }

  // hide the url, pager, number of results, and the sort in the bento results
  .gsc-above-wrapper-area,
  .gsc-url-bottom,
  .gsc-cursor-box.gs-bidi-start-align {
    display: none !important;
  }
  .gsc-webResult.gsc-result:hover {
    border: none !important;
  }

  .gsc-webResult.gsc-result {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1.5px solid $off-white !important;
    border-left: none !important;
  }

  .gsc-expansionArea .gsc-webResult.gsc-result:last-of-type {
    border-bottom: none !important;
  }
}

// thumbnail-inside padding
.gsc-thumbnail-inside {
  padding-left: 0 !important;
}

.gsc-webResult .gsc-url-top {
  font-family: $font-family-serif-base;
  font-size: $base-font-size * 0.8;
  .font-family-serif-loaded & {
    font-family: $font-family-serif;
  }
}

// images
.gsc-table-cell-thumbnail,
.gs-promotion-image-cell {
  padding: 6px !important;
}
.gs-web-image-box {
  margin-right: 4px !important;
}
.dart-google-search {
  > .input-group {
    input[type="text"] {
      height: auto;
      padding: 10px;
    }
    .search-submit {
      border-radius: 4px !important;
      &:hover {
        background-color: $forest-green;
      }
    }
  }
}
.gs-title.gsc-table-cell-thumbnail {
  padding: 0 !important;
}

// remove facets/labels from view
.gsc-tabsArea,
.gs-per-result-labels {
  display: none !important;
}

.gs-webResult.gs-result a.gs-title:hover,
.gs-webResult.gs-result a.gs-title:hover b,
.gs-imageResult a.gs-title:hover,
.gs-imageResult a.gs-title:hover b {
  color: $forest-green !important;
}

.gsc-tabHeader.gsc-tabhInactive,
.gsc-refinementHeader.gsc-refinementhInactive {
  background-color: $forest-green !important;
}
.gsc-results .gsc-cursor-box .gsc-cursor-page {
  padding: 4px;
  background-color: $dart-green !important;
  &:hover {
    background-color: $forest-green !important;
  }
}
.gsc-results .gsc-cursor-box .gsc-cursor-current-page {
  color: $black !important;
  background-color: $light-blue !important;
  &:hover {
    text-decoration: none !important;
    background-color: $light-blue !important;
  }
}

// remove refinement from view
.gsc-refinementHeader.gsc-inline-block.gsc-refinementhActive {
  display: none;
}

.gcsc-more-maybe-branding-root a {
  border: none;
  .gcsc-find-more-on-google-text,
  .gcsc-find-more-on-google-query {
    display: inline-block;
    border-bottom: 1.5px solid;
  }
  .gcsc-find-more-on-google-query {
    padding: 0 4px;
  }
  &:hover {
    border: none;
    .gcsc-find-more-on-google-text,
    .gcsc-find-more-on-google-query {
      border-color: $spring-green;
    }
  }
}

// custom styling for new global nav
.primary-secondary-menus .search--dcl-unified-search-form {
  align-self: center;
  min-width: 193px;
  max-width: 240px;
  form {
    position: relative;
    .form-group {
      margin: 0;
    }
    .submit-wrapper {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
    }
  }
  .search-submit {
    position: absolute;
    top: 4px;
    right: -5px;
    border: none;
    background-color: transparent !important;
    &.dcl-scrolled {
      top: 1px;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      &::before {
        font-size: 14px;
      }
    }
    &::before {
      color: $dart-green;
      font-size: 20px;
    }
  }
  input {
    height: 49px;
    &.dcl-scrolled {
      height: auto;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease;
    }
  }
}

#global-mobile-menu {
  .search--dcl-unified-search-form form {
    display: grid;
    grid-template-columns: 1fr fit-content(100%);
    .submit-wrapper {
      position: static;
      display: block;
      align-self: center;
      width: 100%;
      margin-right: 18px;
    }
    .search-submit {
      @extend .btn-lg;
      @extend %hexagon-background-spring-green;

      position: static;
      z-index: auto;
      float: none;
      border: none;
      font-size: $base-font-size * 1.5;
      line-height: initial;
      &::before {
        @extend %icon-manage-search;

        margin-left: 0;
        vertical-align: top;
        color: $black;
        font-size: $base-font-size * 1.5;
      }
    }
  }
}

// responsive

@media (min-width: em(768px)) {
  .search__more-places-column-2 {
    padding-left: 22px !important;
  }
}

@media (max-width: em(830px)) {
  .result-set-link-count,
  .no-results-link {
    display: table;
    float: none !important;
  }
}

@media (max-width: em(767px)) {
  .search__home-page-search {
    margin: $base-font-size auto;
  }

  .search__results-column-left {
    padding-right: 5px !important;
  }

  .search__results-column-right {
    padding-left: 5px !important;
  }

  .search__more-places-column-2 {
    padding-left: 0 !important;
  }

  .primary-secondary-menus .search--dcl-unified-search-form {
    max-width: 100%;

    input.dcl-scrolled {
      height: 49px;
    }
  }

  .block-dcl-unified-search-website-search-block .form-group {
    margin-bottom: 0;
    input {
      max-width: 80%;
      margin-bottom: 0 !important;
    }
  }
}

// bootstrap search extends
// FIXME: should this move into the global extends file?

.no-results-link {
  @extend .pull-right;
}

.result-cover-image {
  @extend .pull-left;
}

.result-set-counts {
  @extend .col-md-3;
  @extend .col-sm-6;
  @extend .col-xs-12;
}

.best-bet {
  @extend .col-md-12;
  > h3 {
    @extend .col-md-3;
  }
  > div {
    @extend .col-md-9;
  }
}

.more-toggle.show-result-description,
.more-toggle.hide-result-description {
  @extend .pull-right;
}
.search__search-form {
  @extend .row;
  .search--dcl-unified-search-form,
  .search_more-options-link {
    @extend .center-block;
  }
}
.search__search-counts-wrapper {
  @extend .col-md-12;
  @extend .col-sm-12;

  .search__counts {
    @include clearfix;
  }
}

.search__results-wrapper {
  @extend .col-md-12;
}

.search__results-column-left,
.search__results-column-right {
  @extend .col-md-6;
  @extend .col-sm-6;
}

.search__other-searches {
  @extend .row;

  @include clearfix;
  #more-options-for-searching {
    @extend .col-md-12;
  }
}

.search__more-places-column-1,
.search__more-places-column-2 {
  @extend .col-md-6;
  @extend .col-sm-6;
}
